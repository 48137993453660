import React from 'react';
import { Link } from 'react-router-dom';
import finance_case_study from '../../assets/img/369.jpg';
import { useNavigate } from 'react-router-dom';
import {
  HiOutlineCog,
  HiOutlineDesktopComputer,
  HiOutlineClipboardList,
  HiOutlineUserGroup,
  HiOutlineArrowRight,
} from 'react-icons/hi';

export default function Section4() {
  const navigate = useNavigate();

  return (
    <div className="w-full p-6">
      <div className="bg-white border border-gray-200 h-full rounded-xl shadow-lg overflow-hidden flex flex-col">
        <div>
          <img
            src={finance_case_study}
            alt="Finance Case Study"
            className="w-full h-52 object-cover"
          />
        </div>
        <div className="p-8 flex flex-col flex-grow justify-between">
          <div>
            <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
              Case Study
            </div>
            <h2 className="mt-2 text-xl font-bold text-gray-900 dark:text-white">
              Transforming Aerospace Operations with Agent AI and Predictive Analytics
            </h2>
            <p className="mt-2 text-gray-600 dark:text-gray-300">
              Revolutionizing aerospace operations by leveraging Agent AI and predictive analytics for enhanced efficiency and decision-making.
            </p>
            <div className="mt-4 flex flex-wrap gap-4">
              <span className="inline-flex items-center rounded-md bg-blue-50 dark:bg-blue-900/20 px-2 py-1 text-xs font-medium text-blue-700 dark:text-blue-300 ring-1 ring-inset ring-blue-700/10 dark:ring-blue-300/20">
                <HiOutlineCog className="mr-1 h-3 w-3" />
                Predictive Analytics
              </span>
              <span className="inline-flex items-center rounded-md bg-green-50 dark:bg-green-900/20 px-2 py-1 text-xs font-medium text-green-700 dark:text-green-300 ring-1 ring-inset ring-green-600/20 dark:ring-green-300/20">
                <HiOutlineDesktopComputer className="mr-1 h-3 w-3" />
                AI-Powered Automation
              </span>
              <span className="inline-flex items-center rounded-md bg-yellow-50 dark:bg-yellow-900/20 px-2 py-1 text-xs font-medium text-yellow-800 dark:text-yellow-300 ring-1 ring-inset ring-yellow-600/20 dark:ring-yellow-300/20">
                <HiOutlineClipboardList className="mr-1 h-3 w-3" />
                Compliance
              </span>
              <span className="inline-flex items-center rounded-md bg-red-50 dark:bg-red-900/20 px-2 py-1 text-xs font-medium text-red-700 dark:text-red-300 ring-1 ring-inset ring-red-600/20 dark:ring-red-300/20">
                <HiOutlineUserGroup className="mr-1 h-3 w-3" />
                Customer Experience
              </span>
            </div>
            <div className="mt-6">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Key Outcomes:
              </h3>
              <ul className="mt-2 list-disc list-inside text-gray-600 dark:text-gray-300">
                <li>Reduced unplanned maintenance by 35% and increased deployment efficiency by 25%.</li>
                <li>Achieved 20% savings in maintenance operations and lowered inventory costs by 15%.</li>
                <li>Reduced response times by 50% and improved satisfaction scores by 40%.</li>
                <li>Streamlined reporting, reducing effort by 60%, with 100% safety adherence.</li>
              </ul>
            </div>
          </div>
          {/* Button fixed at the bottom */}
          <div className="mt-auto flex justify-center pt-6">
            <Link
              to="/predictive-analysis-casestudy"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Read Full Case Study
              <HiOutlineArrowRight className="ml-2 -mr-1 h-4 w-4" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
