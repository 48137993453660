import React from "react";
import {
  FaCloudUploadAlt,
  FaCloud,
  FaBolt,
  FaShieldAlt,
  FaChartBar,
  FaChartLine,
  FaLightbulb,
  FaUserCheck,
  FaUsers,
  FaLock,
  FaClock,
  FaRocket,
  FaUserLock,
} from "react-icons/fa";
import { FaClipboardCheck, FaFingerprint, FaUserShield } from "react-icons/fa6";
import {
  HiOutlineCog,
  HiOutlineDesktopComputer,
  HiOutlineClipboardList,
  HiOutlineUserGroup,
} from "react-icons/hi";

const CaseStudyPage = () => {
  return (
    <div className="mx-auto px-4 md:px-24 py-8 w-full max-w-7xl">
      <div className="uppercase tracking-wide text-1xl font-bold text-indigo-500">
        Case Study
      </div>
      <h1 className="mt-2 text-4xl font-bold text-gray-900 dark:text-white">
      Transforming Enterprise Security with AI-Driven IAM Solutions
      </h1>
      <h2 className="mt-2 text-2xl font-semibold text-gray-700 dark:text-gray-300">
      AeroAegis Enhances Enterprise Security with AI-Powered Identity and Access Management (IAM)
      </h2>
      <div className="mt-4 flex flex-wrap gap-4">
        <span className="inline-flex items-center rounded-md bg-blue-50 dark:bg-blue-900/20 px-2 py-1 text-xs font-medium text-blue-700 dark:text-blue-300 ring-1 ring-inset ring-blue-700/10 dark:ring-blue-300/20">
          <HiOutlineCog className="mr-1 h-3 w-3" />
          	AI-Powered Identity Security
        </span>
        <span className="inline-flex items-center rounded-md bg-green-50 dark:bg-green-900/20 px-2 py-1 text-xs font-medium text-green-700 dark:text-green-300 ring-1 ring-inset ring-green-600/20 dark:ring-green-300/20">
          <HiOutlineDesktopComputer className="mr-1 h-3 w-3" />
          Intelligent Access Control


        </span>
        <span className="inline-flex items-center rounded-md bg-yellow-50 dark:bg-yellow-900/20 px-2 py-1 text-xs font-medium text-yellow-800 dark:text-yellow-300 ring-1 ring-inset ring-yellow-600/20 dark:ring-yellow-300/20">
          <HiOutlineClipboardList className="mr-1 h-3 w-3" />
          Compliance Automation
        </span>
        <span className="inline-flex items-center rounded-md bg-red-50 dark:bg-red-900/20 px-2 py-1 text-xs font-medium text-red-700 dark:text-red-300 ring-1 ring-inset ring-red-600/20 dark:ring-red-300/20">
          <HiOutlineUserGroup className="mr-1 h-3 w-3" />
          Zero Trust Security Model
        </span>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4 mt-6">Case Study Description</h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
        AeroAegis partnered with a leading financial institution to modernize its identity and access management (IAM) strategy, leveraging AI and GenAI-powered automation to strengthen security, optimize access control, and ensure regulatory compliance. The client faced challenges with identity sprawl, manual access management, and compliance complexity in a multi-cloud environment.
        </p>
        <p className=" text-xl font-semibold dark:text-gray-300 mb-4" >By implementing AI-driven IAM and Zero Trust security architecture, AeroAegis delivered:</p>
        <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div className="flex items-center space-x-3">
            <FaChartLine className="text-2xl text-blue-500" />
            <p className="text-gray-700 dark:text-gray-300">
            	Automated identity lifecycle management to reduce manual overhead.
            </p>
          </div>
          <div className="flex items-center space-x-3">
            <FaUserCheck className="text-2xl text-green-500" />
            <p className="text-gray-700 dark:text-gray-300">
            	AI-driven risk-based authentication for adaptive security enforcement.


            </p>
          </div>
          <div className="flex items-center space-x-3">
            <FaLock className="text-2xl text-yellow-500" />
            <p className="text-gray-700 dark:text-gray-300">
            Zero Trust security framework to mitigate insider and external threats.
            </p>
          </div>
          <div className="flex items-center space-x-3">
            <FaClock className="text-2xl text-purple-500" />
            <p className="text-gray-700 dark:text-gray-300">
            Regulatory compliance automation to streamline audits and reporting.
            </p>
          </div>
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Client Background</h2>
        {/* <p className="text-gray-700 dark:text-gray-300 mb-4">
          The aerospace industry is a dynamic and highly complex sector where
          operational efficiency, predictive maintenance, and regulatory
          compliance are critical. Organizations in this space manage large
          fleets, manufacturing facilities, and maintenance operations,
          requiring advanced systems to ensure seamless data integration,
          real-time decision-making, and safety compliance.
        </p> */}
        <p className="text-gray-700 dark:text-gray-300 mb-4">
        The financial services industry is among the most heavily regulated sectors, with organizations managing vast amounts of sensitive customer and transaction data. Identity security is a critical pillar for maintaining compliance and preventing fraud, yet many institutions struggle with:
        </p>
        <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 mb-4">
          <li>
          Legacy IAM systems that cannot scale for modern digital ecosystems.
          </li>
          <li>
          Manual identity lifecycle processes leading to inefficiencies and security gaps.
          </li>
          <li>
          Siloed access management across multi-cloud and on-prem environments.
          </li>
          <li>
          Increased cyber threats targeting privileged accounts and weak credentials.
          </li>
        </ul>
        {/* <p className="text-gray-700 dark:text-gray-300 mb-4">
          Recognizing the need for advanced solutions, the client partnered with
          AeroAegis to implement Agent AI and Predictive Analytics. AeroAegis’s
          expertise in AI-driven automation and predictive maintenance helped
          address key operational inefficiencies, improve customer service, and
          ensure regulatory compliance.
        </p> */}
        <p className="text-gray-700 dark:text-gray-300 mb-4">
        Our client, a leading global financial institution, was experiencing:
        </p>
        <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 mb-4">
          <li>
          Complex IAM governance, with fragmented identity controls across cloud and on-premises applications.
          </li>
          <li>
        	High operational costs, managing identity provisioning and role-based access manually.
          </li>
          <li>
          	Security vulnerabilities, with excessive privileged access exposing critical systems to insider threats.
          </li>
          <li>
          	Compliance risks, struggling to meet evolving GDPR, PCI DSS, and ISO 27001 standards.
          </li>
        </ul>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
        To address these challenges, the client engaged AeroAegis to implement an AI-powered IAM framework that would automate identity governance, enable Zero Trust security, and strengthen compliance enforcement.
        </p>
      </div>


      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
          Challenges Faced by the Client
        </h2>
        {/* <p className="text-gray-700 dark:text-gray-300 mb-2">
          Our client, a leading aerospace organization specializing in
          commercial aviation and defense solutions, faced growing operational
          complexities due to the increasing demand for efficient fleet
          management, predictive maintenance, and seamless customer service. The
          organization operates a global network of aircraft fleets,
          manufacturing facilities, and maintenance operations, servicing both
          government and private sector clients.
        </p> */}
        <ol className="list-decimal list-inside text-gray-700 dark:text-gray-300">
          <li className="mb-4">
            <strong>Manual and Inefficient Identity Lifecycle Management</strong>
            {/* <p className="text-gray-700 dark:text-gray-300 mt-2">
              Manual monitoring of aircraft components led to delays in
              identifying and resolving potential issues. High costs were
              incurred due to unplanned maintenance and fleet downtime.
            </p> */}
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
              Onboarding and offboarding processes were slow, taking up to 10 days per user due to manual approvals.


              </li>
              <li>
              Excessive orphaned accounts posed security risks due to lack of timely deprovisioning.
              </li>
              <li>
              No centralized governance, leading to duplicate and inconsistent access permissions across systems.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>High-Risk Access Control and Privilege Mismanagement</strong>
            {/* <p className="text-gray-700 dark:text-gray-300 mt-2">
              Lack of real-time visibility into asset performance made it
              difficult to predict and prevent failures. There was also limited
              data integration between manufacturing, operations, and
              maintenance teams.
            </p> */}
           


            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
              Overprivileged accounts had unrestricted access to critical financial systems.
              </li>
              <li>
              Lack of real-time monitoring made privileged access abuse detection nearly impossible.
              </li>
              <li>
              No role-based access enforcement (RBAC), leading to broad access assignments instead of fine-grained controls.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Increasing Cybersecurity Threats and Insider Risks</strong>
            {/* <p className="text-gray-700 dark:text-gray-300 mt-2">
              Fragmented customer communication channels led to inconsistent
              support for aviation customers and suppliers. High response times
              for resolving queries related to supply chain, spare parts, and
              maintenance schedules.
            </p> */}
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
              Stolen credentials and phishing attacks targeted employees and third-party vendors.

              </li>
              <li>Weak authentication mechanisms relied on static passwords instead of risk-based authentication.</li>
              <li>No behavioral analytics to detect anomalous user activity across the enterprise.</li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Regulatory Compliance Complexity</strong>
            {/* <p className="text-gray-700 dark:text-gray-300 mt-2">
              Meeting strict aviation safety and compliance standards (e.g., FAA
              and EASA) required significant manual oversight and reporting
              efforts.
            </p> */}
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
              Adhering to GDPR, PCI DSS, ISO 27001, and SOC 2 required time-consuming manual reporting.
              </li>
              <li>
              Lack of real-time audit trails made compliance audits difficult and resource-intensive.
              </li>
              <li>
              No automated risk assessments for identity-related security gaps.
              </li>
            </ul>
          </li>
        </ol>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">AeroAegis's Approach</h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
        To modernize the client’s security infrastructure, AeroAegis deployed a comprehensive AI-driven IAM framework built on industry-leading platforms like Okta, ForgeRock, Ping Identity, and Azure AD. The solution provided end-to-end automation, intelligent access control, and real-time compliance management.
        </p>
        <ol className="list-decimal list-inside text-gray-700 dark:text-gray-300">
          <li className="mb-4">
            <strong>Comprehensive Identity Assessment and Roadmap</strong>
            <p className="ml-6 mt-2">
            AeroAegis began with a detailed evaluation of the client’s IAM maturity, identifying gaps and risk areas. The roadmap focused on:
            </p>
            
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
              Unifying identity governance across hybrid cloud environments.


              </li>
              <li>
              Implementing AI-driven automation for identity provisioning and deprovisioning.
              </li>
              <li>
              Strengthening authentication security with adaptive risk-based controls.
              </li>
              <li>
              Achieving regulatory compliance through AI-powered audit readiness.
              </li>
            </ul>
            
          </li>
          <li className="mb-4">
            <strong>AI-Driven Identity Lifecycle Management</strong>
            {/* <p className="ml-6 mt-2">
              AeroAegis designed and implemented predictive maintenance
              analytics, integrating IoT sensors across the fleet to collect
              real-time data on aircraft performance. Machine learning
              algorithms were applied to predict potential failures, proactively
              schedule maintenance, and reduce unplanned downtime.
            </p> */}
            

            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
              Automated user provisioning and deprovisioning reduced access assignment delays from 10 days to under 24 hours.
              </li>
              <li>
              Role-based access control (RBAC) and least privilege enforcement minimized excessive permissions.
              </li>
              <li>
              AI-powered identity intelligence continuously monitored access patterns and flagged anomalous behavior.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Zero Trust Security Architecture Implementation</strong>
            {/* <p className="ml-6 mt-2">
              To streamline customer interactions, AeroAegis deployed AI-powered
              virtual assistants that handled routine queries related to
              maintenance schedules, spare parts, and supply chain logistics.
              Natural Language Processing (NLP) was used to support global
              customers in multiple languages.
            </p> */}
     

            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
              Implemented continuous authentication and risk-based access policies, ensuring users were validated at every session.
              </li>
              <li>
              Enforced micro-segmentation, restricting lateral movement within internal systems.
              </li>
              <li>
              Dynamic policy enforcement using AI to adjust access controls based on real-time risk signals.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Intelligent Privileged Access Management (PAM)</strong>
            {/* <p className="ml-6 mt-2">
              AeroAegis implemented AI-driven compliance tools to automate
              reporting and ensure adherence to aviation safety standards like
              FAA and EASA. These tools reduced manual oversight, streamlined
              regulatory reporting, and improved audit readiness.
            </p> */}
           

            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
              Centralized privileged account vaulting, removing hardcoded credentials.
              </li>
              <li>
              Just-in-time (JIT) access granted privileged permissions only when needed, reducing attack surfaces.
              </li>
              <li>
              Session monitoring and AI-driven anomaly detection helped identify unusual privileged access behaviors.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Behavioral AI for Threat Detection</strong>
            {/* <p className="ml-6 mt-2">
              Predictive models were developed to optimize fleet deployment,
              ensuring efficient use of aircraft and minimizing operational
              costs. Real-time tracking of aircraft performance metrics helped
              identify underutilized assets and improve scheduling.
            </p> */}
           

            <ul className="list-disc list-inside ml-6 mt-2">
              <li>Deployed GenAI-powered behavioral analytics, tracking user activity across systems to detect suspicious access attempts.</li>
              <li>
              AI-driven adaptive authentication, escalating to multi-factor authentication (MFA) based on behavioral risk.
              </li>
              <li>
              Automated threat response mechanisms, revoking risky access in real time.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Compliance Automation & Audit Readiness</strong>
            {/* <p className="ml-6 mt-2">
              AeroAegis integrated a big data analytics platform to enable
              real-time insights across fleet operations, maintenance, and
              customer service. This platform leveraged predictive analytics to
              anticipate maintenance needs and enhance decision-making.
            </p> */}
           

            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
              AI-based compliance dashboards provided real-time visibility into IAM risks and policy violations.
              </li>
              <li>
              Automated reporting and pre-configured compliance controls ensured continuous adherence to GDPR, PCI DSS, and ISO 27001.
              </li>
              <li>
              Blockchain-backed immutable audit trails for enhanced accountability and regulatory transparency.
              </li>
            </ul>
          </li>
        </ol>
      </div>

     

      <div class="mb-8">
        <h2 class="text-2xl font-bold mb-4">Key Outcomes and Benefits</h2>
        <ol class="list-decimal list-inside text-gray-700 dark:text-gray-300">
          <li class="mb-4">
            <strong> Enhanced Security and Risk Reduction</strong>
            <ul class="list-disc list-inside ml-6 mt-2">
              <li>
              90% reduction in security incidents related to unauthorized access and credential misuse.


              </li>
              <li>
              Eliminated orphaned accounts, ensuring deprovisioning was executed in real time.
              </li>
              <li>
              Adaptive authentication cut phishing attack success rates by 85%.
              </li>
            </ul>
          </li>

          <li class="mb-4">
            <strong>Improved Operational Efficiency</strong>
           

            <ul class="list-disc list-inside ml-6 mt-2">
              <li>
              IAM automation reduced identity provisioning time by 80%, enabling instant user onboarding.
              </li>
              <li>
              Reduced manual IT workload, allowing security teams to focus on strategic initiatives.
              </li>
              <li>
              Self-service access requests empowered employees while maintaining governance controls.
              </li>
            </ul>
          </li>

          <li class="mb-4">
            <strong>AI-Powered Compliance and Audit Readiness</strong>
            <ul class="list-disc list-inside ml-6 mt-2">
              <li>
              Automated compliance enforcement ensured 100% adherence to GDPR, PCI DSS, and ISO 27001.
              </li>
              <li>
              Audit preparation time decreased by 60%, eliminating manual effort in tracking identity-related compliance.
              </li>
              <li>
              Real-time risk scoring helped proactively address identity vulnerabilities.
              </li>
            </ul>
          </li>

          <li class="mb-4">
            <strong>Cost Savings and Scalabilitys</strong>
            

            <ul class="list-disc list-inside ml-6 mt-2">
              <li>
              30% reduction in IAM-related operational costs through automation.
              </li>
              <li>
              Scalable IAM framework supported business expansion while maintaining security.
              </li>
              <li>
              AI-driven identity insights provided continuous security optimization.
              </li>
            </ul>
          </li>

          
        </ol>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
         Transforming IAM with AI & Zero Trust Security
        </h2>
        <p className="text-gray-700 dark:text-gray-300 mb-6">
        The success of this IAM transformation demonstrates how AI and GenAI-driven security frameworks can fortify enterprises against evolving threats while driving operational efficiency.
        </p>
        <div className="grid md:grid-cols-2 gap-8">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
        <h3 className="text-xl font-semibold mb-4 flex items-center">
          <FaLock className="mr-2 text-red-500" />
          Secure IAM Framework
        </h3>
        <p className="text-gray-700 dark:text-gray-300">
          Implemented a scalable Identity and Access Management (IAM) framework
          to ensure robust security, reducing unauthorized access risks and
          enhancing data protection.
        </p>
      </div>
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
        <h3 className="text-xl font-semibold mb-4 flex items-center">
          <FaUserShield className="mr-2 text-blue-500" />
          Reduced Security Risks
        </h3>
        <p className="text-gray-700 dark:text-gray-300">
          Strengthened security posture by minimizing vulnerabilities and
          reducing regulatory burdens, ensuring compliance with global security
          standards.
        </p>
      </div>
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
        <h3 className="text-xl font-semibold mb-4 flex items-center">
          <FaFingerprint className="mr-2 text-green-500" />
          Frictionless Access Control
        </h3>
        <p className="text-gray-700 dark:text-gray-300">
          Enhanced user experiences with seamless and secure authentication,
          allowing quick and hassle-free access while maintaining high security
          standards.
        </p>
      </div>
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
        <h3 className="text-xl font-semibold mb-4 flex items-center">
          <FaShieldAlt className="mr-2 text-purple-500" />
          Automated Access Governance
        </h3>
        <p className="text-gray-700 dark:text-gray-300">
          Implemented automated access governance to monitor and manage user
          permissions dynamically, reducing the risk of privilege misuse and
          ensuring compliance.
        </p>
      </div>
    </div>

      </div>

      <div className="bg-blue-50 dark:bg-blue-900 border-l-4 border-blue-500 p-4 mb-8">
        <p className="text-blue-700 dark:text-blue-300">
        This case study highlights the transformative impact of AI-driven IAM on enterprise security. By automating identity lifecycle management, enforcing Zero Trust policies, and leveraging AI for real-time threat detection, AeroAegis enabled the client to achieve.
        </p>
      </div>

      <div className="mb-8">
  <h2 className="text-2xl font-bold mb-4">
    Why Choose AeroAegis for IAM & Security?
  </h2>
  <p className="text-gray-700 dark:text-gray-300 mb-4">
    AeroAegis specializes in delivering AI-powered identity security solutions
    that help businesses safeguard digital assets, mitigate risks, and ensure
    compliance. Our expertise in IAM frameworks, Zero Trust architecture, and
    automated compliance ensures robust security tailored to your needs.
  </p>
  <div className="grid md:grid-cols-3 gap-4">
    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
      <FaShieldAlt className="text-3xl text-blue-500 mb-2" />
      <h3 className="font-semibold mb-2">Strengthen Identity Governance</h3>
      <p className="text-sm text-gray-600 dark:text-gray-400">
        Secure and streamline identity management with leading IAM tools like
        Okta, ForgeRock, and Azure AD.
      </p>
    </div>
    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
      <FaUserLock className="text-3xl text-green-500 mb-2" />
      <h3 className="font-semibold mb-2">Zero Trust Security</h3>
      <p className="text-sm text-gray-600 dark:text-gray-400">
        Implement a Zero Trust framework to minimize cyber risks and protect
        critical systems.
      </p>
    </div>
    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
      <FaClipboardCheck className="text-3xl text-purple-500 mb-2" />
      <h3 className="font-semibold mb-2">Automated Compliance</h3>
      <p className="text-sm text-gray-600 dark:text-gray-400">
        Automate compliance processes, enhance audit readiness, and ensure
        regulatory adherence.
      </p>
    </div>
  </div>
</div>

      <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg mb-8">
        <h2 className="text-2xl font-bold mb-4">
          Let's Transform Your Enterprise Security
        </h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
        Ready to transform your enterprise security? Contact AeroAegis today and build a future-proof IAM strategy tailored to your business needs.
        </p>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          Visit{" "}
          <a
            href="http://www.aeroaegis.com"
            className="text-blue-500 hover:underline"
          >
            www.aeroaegis.com
          </a>{" "}
          or reach out to our experts for a personalized consultation. Together,
          we can build innovative, resilient, and scalable solutions that drive
          operational excellence in the aerospace industry.
        </p>
        <p className="text-gray-700 dark:text-gray-300 font-semibold">
          Let's shape the future of enterprise security—one solution at a time.
        </p>
      </div>
    </div>
  );
};

export default CaseStudyPage;