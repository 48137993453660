import React from "react";
import {
  MdOutlineAttachMoney,
  MdOutlineHealthAndSafety,
  MdStorefront,
  MdOutlineFactory,
  MdOutlinePower,
} from "react-icons/md";
import { MdDeviceUnknown } from "react-icons/md";
import { LuBan } from "react-icons/lu";
import { MdDevices } from "react-icons/md";
import img1 from "../../../../assets/img/Services/IAMSecuritySolutions/zero-trust-industrie.jpg"; // Ensure correct image path

export default function MainSection5() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        {/* Image Section - Now on Left */}

        {/* Content Section - Now on Right */}
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Endpoint and Device Security
            </h1>
            {/* <p className="text-lg text-gray-600">
              Zero Trust security is transforming industries by securing
              critical assets, infrastructure, and sensitive data. AeroAegis
              enables businesses to implement custom Zero Trust security
              frameworks for enhanced cybersecurity.
            </p> */}
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 - Finance & Banking */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdDeviceUnknown className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Device Trust Assessment
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Continuously verify device health and enforce access controls
                based on compliance with security policies.
              </p>
            </div>

            {/* Feature 2 - Healthcare & HIPAA Compliance */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdDevices className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Bring Your Own Device (BYOD) Security
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Secure employee-owned devices with endpoint monitoring and
                conditional access policies.
              </p>
            </div>

            {/* Feature 3 - Retail & E-Commerce Security */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <LuBan className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Zero Trust for IoT
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Enforce security policies for IoT devices, ensuring they are
                authenticated and monitored in real time.
              </p>
            </div>

            {/* Feature 4 - Manufacturing & IoT Security */}
            {/* <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineFactory className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Manufacturing & IoT Security
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Secure industrial IoT devices, smart factories, and supply
                chains from cyber threats.
              </p>
            </div> */}

            {/* Feature 5 - Energy & Critical Infrastructure */}
            {/* <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlinePower className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Energy & Critical Infrastructure
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Prevent cyberattacks on smart grids, utilities, and energy
                infrastructure with Zero Trust principles.
              </p>
            </div> */}
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Zero Trust Industry Use Cases"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}