import React from 'react';
import { MdSecurity, MdCloudDone, MdOutlineGppGood, MdAnalytics, MdManageAccounts, MdOutlineVpnLock } from 'react-icons/md';
import { Link } from 'react-router-dom';

const FinalSection = () => {
  return (
    <div className="bg-gray-100">
      {/* Hero Section */}
      <section className="bg-blue-600 text-white py-20">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
            Transform Your Cloud Security Framework with AeroAegis
          </h1>
          <p className="text-xl mb-8">
          As organizations adopt cloud-first strategies, securing access and managing identities across cloud environments becomes paramount. At AeroAegis, we combine the latest technologies, deep expertise, and a customer-centric approach to deliver Cloud IAM solutions that empower businesses to thrive in the digital age. Visit www.aeroaegis.com or connect with our IAM experts for a personalized consultation. Together, we’ll build a secure, scalable, and efficient cloud ecosystem for your business.
          </p>
          <Link to={'/contact-us'} className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
          Contact us
          </Link>
        </div>
      </section>

      {/* Why Choose AeroAegis IAM? */}
      {/* <section className="py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">
            Why AeroAegis for Cloud IAM?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <FeatureCard
              icon={<MdSecurity className="text-4xl text-blue-600" />}
              title="Zero-Trust Security"
              description="Ensure secure authentication, risk-based access, and least-privilege controls."
            />
            <FeatureCard
              icon={<MdCloudDone className="text-4xl text-blue-600" />}
              title="Cloud-Native Identity Management"
              description={<span>Seamlessly integrate IAM with AWS, Azure, and Google Cloud.</span>}
            />
            <FeatureCard
              icon={<MdOutlineGppGood className="text-4xl text-blue-600" />}
              title="Regulatory Compliance"
              description='Automate compliance for GDPR, HIPAA, PCI DSS, and SOX.'
            />
            <FeatureCard
              icon={<MdAnalytics className="text-4xl text-blue-600" />}
              title="AI-Powered Identity Insights"
              description="Leverage behavioral analytics and AI-driven IAM security monitoring."
            />
            <FeatureCard
              icon={<MdManageAccounts className="text-4xl text-blue-600" />}
              title="End-to-End Identity Lifecycle Management"
              description={<span>From user provisioning to de-provisioning, manage identities at scale.</span>}
            />
            <FeatureCard
              icon={<MdOutlineVpnLock className="text-4xl text-blue-600" />}
              title="Adaptive Authentication"
              description={
                'Deploy risk-based authentication policies to enhance cloud security.'
              }
            />
          </div>
        </div>
      </section> */}

      {/* Footer */}
      {/* <footer className="bg-blue-600 text-white py-8">
        <div className="container mx-auto px-4 text-center">
          <p className="mb-4 text-lg">
            Ready to strengthen your cloud identity security? Talk to our IAM experts today! 🔒
          </p>
          <Link to={'/contact-us'} className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
            Schedule a Consultation
          </Link>
        </div>
      </footer> */}
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="flex items-center justify-center mb-4">{icon}</div>
      <h3 className="text-xl font-semibold mb-2 text-center">{title}</h3>
      <p className="text-gray-600 text-center">{description}</p>
    </div>
  );
};

export default FinalSection;
