import React from "react";
import { MdOutlineLightbulb } from "react-icons/md"; // AI/ML Solutions
import { MdOutlineCloudQueue } from "react-icons/md"; // Cloud & Edge Scaling
import { MdOutlineSpeed } from "react-icons/md"; // Optimization
import { MdOutlineBuild } from "react-icons/md"; // Automation
import { BsDatabaseLock } from "react-icons/bs";
import { FaLightbulb, FaPencilRuler, FaRocket, FaCogs } from "react-icons/fa";
import { GoGraph } from "react-icons/go";
import { FaEye } from "react-icons/fa";
import { FiThumbsDown } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { LuBan, LuShieldCheck, LuEye, LuBadgeCheck } from "react-icons/lu";
import cyberark from "../../../../assets/img/Services/IAMSecuritySolutions/cyberark-logo.svg";
import BeyondTrust from "../../../../assets/img/Services/IAMSecuritySolutions/BeyondTrust.svg";
import delina from "../../../../assets/img/Services/IAMSecuritySolutions/delinea-logo.png";
import { VscAzure } from "react-icons/vsc";
import { GiSparkles } from "react-icons/gi";

// export default function MainSection6() {
//   return (
//     <div>
//       <div className="mt-6 px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-5">
//         <div className="flex flex-col my-2 lg:justify-between lg:flex-row ">
//           <h2 className="font-sans text-2xl font-bold tracking-tight text-gray-900 md:text-3xl sm:text-2xl sm:leading-none  group">
//             <span className="inline-block mb-1 sm:mb-4">
//               Technology Partners and Tools We Leverage
//             </span>
//             <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
//           </h2>
//         </div>
//         <p className="text-gray-700 font-semibold text-lg lg:text-xl py-5">
//           AeroAegis partners with leading PAM providers to deliver robust
//           solutions tailored to your organization’s needs:
//         </p>

//         <section className="py-4 bg-base-100">
//           <div className="container mx-auto px-4">
//             <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
//               <div className="card border border-primary shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2">
//                 <div className="card-body items-center  flex flex-col">
//                   <img src={cyberark} alt="cyberark" />
//                   <div className="mt-auto">
//                     <h3 className="card-title text-xl">CyberArk</h3>
//                     <p>
//                       Industry-leading PAM platform offering credential
//                       vaulting, session monitoring, and real-time threat
//                       detection.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//               <div className="card border border-primary shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2">
//                 <div className="card-body items-center  flex flex-col">
//                   <img
//                     width={50}
//                     height={50}
//                     src={BeyondTrust}
//                     alt="beyond trust"
//                   />
//                   <div className="mt-auto">
//                     <h3 className="card-title text-xl">BeyondTrust</h3>
//                     <p>
//                       Unified PAM solutions for securing privileged accounts,
//                       endpoints, and remote access.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//               <div className="card border border-primary shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2">
//                 <div className="card-body items-center  flex flex-col">
//                   <img width={120} src={delina} alt="delina" className="py-4" />{" "}
//                   <div className="mt-auto">
//                     <h3 className="card-title text-xl">Thycotic (Delinea)</h3>
//                     <p>
//                       Advanced PAM solutions with seamless integration and
//                       automation for password and access management.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//               <div className="card border border-primary shadow-xl hover:shadow-2xl transition-all md:col-start-2	 duration-300 transform hover:-translate-y-2">
//                 <div className="card-body items-center  flex flex-col">
//                   <div className="text-[55px] text-sky-500">
//                     <VscAzure />
//                   </div>
//                   <div className="mt-auto">
//                     <h3 className="card-title text-xl">
//                       Microsoft Azure Privileged Identity Management (PIM)
//                     </h3>
//                     <p>
//                       Cloud-based privileged access controls designed for Azure
//                       and hybrid environments.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//       </div>
//     </div>
//   );
// }

const TechPartnerCard = ({ icon, title, description }) => (
  <div className="group relative p-8 bg-white rounded-2xl hover:shadow-2xl transition-all duration-500 border border-gray-100 overflow-hidden shadow-lg">
    <div className="absolute inset-0 bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
    <div className="absolute top-0 right-0 w-32 h-32 bg-blue-100 rounded-full blur-3xl opacity-20 -mr-16 -mt-16" />
    <div className="relative">
      <div className="flex flex-col items-center justify-center mb-6">
        {icon}
        <h3 className="w-full text-xl font-bold text-gray-800 group-hover:text-blue-700 transition-colors duration-300">
          {title}
        </h3>
      </div>
      <p className="text-gray-600 leading-relaxed">{description}</p>
      <div className="absolute top-2 right-2">
        <GiSparkles className="w-6 h-6 text-blue-300 opacity-60" />
      </div>
    </div>
  </div>
);

const MainSection6 = () => {
  const partners = [
    {
      icon: <img src={cyberark} alt="cyberark" className="h-20" />,
      title: "CyberArk",
      description:
        "Industry-leading PAM platform offering credential vaulting, session monitoring, and real-time threat detection. ",
    },
    {
      icon: <img src={BeyondTrust} alt="beyond trust" className="py-3 h-20" />,
      title: "BeyondTrust",
      description:
        "Unified PAM solutions for securing privileged accounts,endpoints, and remote access.",
    },
    {
      icon: <img src={delina} alt="delina" className="py-6 h-20" />,
      title: "Thycotic (Delinea)",
      description:
        "Advanced PAM solutions with seamless integration and automation for password and access management.",
    },
    {
      icon: (
        <VscAzure
          style={{ fontSize: "50px" }}
          className="text-sky-500 py-4 h-20"
        />
      ),
      title: "Microsoft Azure PIM",
      description:
        "Cloud-based privileged access controls designed for Azure and hybrid environments.",
    },
  ];

  return (
    <>
      <section className="py-24 bg-white relative overflow-hidden">
        <div className="absolute inset-0 bg-grid-gray-100 opacity-20" />
        <div className="absolute inset-0 bg-white" />
        <div className="container mx-auto px-4 relative">
          <div className="max-w-3xl mx-auto text-center mb-20">
            <h2 className="text-5xl font-bold text-gray-900 mb-6 leading-tight">
              Our Technology Partners
            </h2>
            <p className="text-xl text-gray-600 leading-relaxed">
              AeroAegis partners with leading PAM providers to deliver robust
              solutions tailored to your organization’s needs
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {partners.map((partner, index) => (
              <TechPartnerCard key={index} {...partner} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default MainSection6;