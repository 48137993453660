import img3 from "../../../../assets/img/Products/AeroGenix/deploy.svg";
import img1 from "../../../../assets/img/Products/AeroGenix/Data points-rafiki.svg";
import img4 from "../../../../assets/img/Products/AeroGenix/monitoring.svg";
import img2 from "../../../../assets/img/Products/AeroGenix/people creating robot-rafiki.svg";

export default function Implementationservice() {
  const steps = [
    {
      number: "1",
      title: "IAM Strategy and Assessment",
      description: [
        "Our experts conduct a comprehensive analysis of your existing identity systems.",
        "Thus, identifying the gaps and create a tailored IAM roadmap for your organization.",
      ],
      image: img1,
    },
    {
      number: "2",
      title: "IAM System Design and Deployment",
      description: [
        "Design a scalable IAM architecture that integrates with your current IT ecosystem.",
        "Implement leading IAM technologies like ForgeRock, Okta, or Ping Identity to meet your unique needs.",
      ],

      image: img2,
    },
    {
      number: "3",
      title: "Migration and Integration Services",
      description: [
        "Seamlessly migrate legacy identity systems to modern IAM platforms with minimal disruption.",
        "Integrate IAM solutions with your existing applications, cloud environments, and workflows.",
      ],

      image: img3,
    },
    {
      number: "4",
      title: "Compliance and Governance Automation",
      description: [
        "Implement real-time monitoring and reporting tools to simplify compliance with regulations like GDPR, HIPAA, and PCI DSS.",
        "Automate governance processes, including access reviews, role management, and audit reporting.",
      ],

      image: img4,
    },
    {
      number: "5",
      title: "Managed IAM Services",
      description: [
        "Offload IAM management with our ongoing monitoring, maintenance, and optimization services.",
        "Receive proactive support to address emerging threats and evolving business needs.",
      ],

      image: img4,
    },
  ];

  return (
    <div className="px-4 py-16 sm:px-6 lg:px-8 bg-white">
     <div className="flex flex-col mb-6 pb-12 justify-center lg:justify-center lg:flex-row md:mb-8">
      <h2 className="max-w-lg font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
        <span className="inline-block mb-1 sm:mb-4">Our IAM Implementation Services</span>
        <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
      </h2>
    </div>
    
      <div className="flex flex-wrap justify-center gap-8 bg-white">
        {steps.map((step) => (
          <div
            key={step.number}
            className="bg-white max-w-[450px] xl:max-w-[550px] rounded-lg p-8  shadow-xl hover:-translate-y-1 hover:shadow-2xl  transition-all duration-300 border border-primary"
          >
            <div className="flex flex-col justify-between items-start gap-6">
              <div className="space-y-4">
                <div className="flex-shrink-0">
                  <img
                    src={step.image}
                    alt={`Step ${step.number} - ${step.title}`}
                    className="w-32 h-32  m-auto object-contain"
                  />
                </div>
                <h3 className="text-xl font-semibold text-gray-900">
                  {step.title}
                </h3>
                <ul className="text-gray-500 list-disc	pl-5 ">
                  {step.description.map((e) => (
                    <li className="m-2">{e}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}