import React from "react";
import {
  MdAccountCircle,
  MdOutlineLock,
  MdAdminPanelSettings,
  MdVerifiedUser
} from "react-icons/md";
import img1 from "../../../../assets/img/Services/IAMSecuritySolutions/CoreIAMFeatures.jpg";

export default function MainSection3() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        
        {/* Image Section */}
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Core IAM Features"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>

        {/* Content Section */}
        <div className="space-y-8">
          {/* Heading and Intro */}
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Single Sign-On (SSO) for Cloud Applications
            </h1>
            <p className="text-lg text-gray-600">
              Provide a seamless user experience with secure access to all cloud applications using a single set of credentials.
            </p>
          </div>

          {/* Features */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 - Single Sign-On (SSO) */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdAccountCircle className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Federated SSO Integration</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Integrate SSO with federated identity standards such as SAML,
                OAuth, and OpenID Connect.
              </p>
            </div>

            {/* Feature 2 - Multi-Factor Authentication (MFA) */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineLock className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Anywhere Access</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Enable users to access cloud and on-premises applications from
                any device, anytime, anywhere.
              </p>
            </div>

            {/* Feature 3 - Role-Based Access Control (RBAC) */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdAdminPanelSettings className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Passwordless Productivity</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Reduce password fatigue and improve productivity while
                maintaining strong access control.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
