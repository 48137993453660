import React from "react";
import {
  FaRocket,
  FaChartLine,
  FaCog,
  FaIndustry,
  FaDatabase,
  FaBrain,
} from "react-icons/fa";
import { Link } from "react-router-dom";

import { MdHealthAndSafety } from "react-icons/md";
import { BsBank2 } from "react-icons/bs";
import { GiFactory } from "react-icons/gi";
import { FaBuildingCircleCheck } from "react-icons/fa6";
import { LuFactory } from "react-icons/lu";

const IndustriesWeServe = () => {
  return (
    <div className=" bg-gray-100">
      <section className="py-5 ">
        <div className="container mx-auto px-4">
          <div className="flex flex-col mb-6 justify-center items-center lg:justify-center lg:flex-row md:mb-8">
            <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group md:text-left lg:text-center text-left sm:text-left">
              <span className="inline-block mb-1 sm:mb-4 mx-auto md:text-left lg:text-center text-left sm:text-left">
                Industries We Serve
              </span>
              <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
              <p className="text-lg text-gray-600 md:text-left lg:text-center text-left sm:text-left">
              Our IAM solutions are designed to address the unique needs of various industries, including:
              </p>
            </h2>
          </div>

          <div className="flex justify-center flex-wrap gap-8 mb-8 ">
            <FeatureCard
              icon={<BsBank2 className="text-4xl text-blue-600" />}
              title="Finance & Banking"
              description="Protect customer data and ensure regulatory compliance."
            />
            <FeatureCard
              icon={<MdHealthAndSafety className="text-4xl text-blue-600" />}
              title="Healthcare"
              description="Secure patient information and simplify HIPAA compliance."
            />
            <FeatureCard
              icon={<GiFactory className="text-4xl text-blue-600" />}
              title="Retail & E-Commerce"
              description=" Safeguard customer transactions and enable secure, frictionless shopping experiences."
              // className="lg:hidden"
            />
            <FeatureCard
              icon={
                <FaBuildingCircleCheck className="text-4xl text-blue-600" />
              }
              title="Energy & Utilities"
              description="Protect critical infrastructure with advanced access controls."
            />
            <FeatureCard
              icon={
                <LuFactory className="text-4xl text-blue-600" />
              }
              title="Manufacturing"
              description="Secure IoT-connected devices and ensure safe operations across global supply chains."
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export function FeatureCard({ icon, title, description, className }) {
  return (
    <div
      className={`card bg-white p-6 rounded-lg shadow-md w-[450px]
    ${className ? className : ""}
    `}
    >
      <div className="flex items-center justify-center mb-4">{icon}</div>
      <h3 className="text-xl font-semibold mb-2 text-center">{title}</h3>
      <p className="text-gray-600 text-center">{description}</p>
    </div>
  );
}

export default IndustriesWeServe;
