import React from "react";
import hero_img from "../../../../assets/img/Services/IAMSecuritySolutions/heroooo.png";
import {
  FaShieldAlt,
  FaUserLock,
  FaKey,
  FaCogs,
  FaRobot,
} from "react-icons/fa";

export default function HeroSection() {
  return (
    <div className="relative flex flex-col py-16 lg:pt-0 lg:flex-col lg:pb-0">
      <div className="inset-y-0 top-0 right-0 z-0  xl:max-w-[650px] lg:max-w-[520px]  px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0  lg:absolute xl:px-0">
        <svg
          className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
          viewBox="0 0 100 100"
          fill="currentColor"
          preserveAspectRatio="none slice"
        >
          <path d="M50 0H100L50 100H0L50 0Z" />
        </svg>
        <img
          className="object-cover w-full bg-fixed h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
          src={hero_img}
          alt="hero"
        />
      </div>
      <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
        <div className="lg:mb-16 pr-14 mt-10 md:mt-10 lg:mt-10 xl:mt-14 pt-2 lg:my-20 lg:max-w-2xl ">
          <div className="p-2 w-6 h-2 flex bg-[#232f7f] rounded-full"></div>
          <h2 className="mb-5 text-3xl font-bold tracking-wide text-gray-900 lg:text-5xl sm:leading-none">
            Regulatory Compliance and Audit Readiness
          </h2>
          <p className="pr-18 lg:pr-20 xl:pr-5 text-base text-gray-700 md:text-lg">
            In an era of stringent regulations and evolving data privacy
            standards, organizations face increasing pressure to ensure
            compliance while safeguarding sensitive information. AeroAegis's
            Regulatory Compliance and Audit Readiness Solutions empower
            businesses to navigate complex regulatory landscapes with ease,
            reduce risks, and build trust with stakeholders. Our tailored
            solutions streamline compliance workflows, automate audit
            preparation, and ensure continuous adherence to global standards
            like GDPR, HIPAA, PCI DSS, and industry-specific regulations. By
            integrating advanced technologies like AI, blockchain, and
            cloud-native tools, AeroAegis ensures your organization is always
            audit-ready.
          </p>
        </div>
      </div>
    </div>
  );
}