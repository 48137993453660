import React from "react";
import { MdPolicy, MdSecurity, MdOutlineInsights, MdOutlineGppGood } from "react-icons/md";
import img1 from "../../../../assets/img/Services/IAMSecuritySolutions/IAMGovernance.jpg"; // Update with actual image

export default function MainSection4() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Multi-Factor Authentication (MFA) for Cloud Security
            </h1>
            <p className="text-lg text-gray-600">
            Add an additional layer of security to cloud access with advanced MFA solutions</p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 - Policy-Based Access Governance */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdPolicy className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Adaptive MFA Deployment</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Deploy adaptive MFA, incorporating factors like biometrics, hardware tokens, SMS codes, and mobile authenticator apps.</p>
            </div>

            {/* Feature 2 - AI-Driven Risk-Based Authentication */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdSecurity className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Dynamic Risk-Based Authentication</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Enhance security with risk-based authentication, dynamically adjusting authentication requirements based on user behavior and location.
              </p>
            </div>

            {/* Feature 3 - Compliance & Audit Trail Monitoring */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineGppGood className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Mitigate Credential Compromise Risks</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Prevent unauthorized access even in the event of credential compromise.
              </p>
            </div>
          </div>
        </div>

        {/* Image Section */}
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="IAM Governance & Security"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}
