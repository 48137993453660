import React from "react";
import {
  FaFlask,
  FaClipboardCheck,
  FaChartLine,
  FaUserMd,
} from "react-icons/fa";

import img1 from "../../../assets/img/Industries/Pharmaceuticals/6.jpg";
import img2 from "../../../assets/img/Industries/Pharmaceuticals/7.jpg";
import img3 from "../../../assets/img/Industries/Pharmaceuticals/8.jpg";
import img4 from "../../../assets/img/Industries/Pharmaceuticals/9.jpg";

const HealthcareCard = ({ title, description, icon, imageUrl }) => (
  <div className="bg-white rounded-2xl shadow-xl w-full overflow-hidden transition-all duration-300 hover:shadow-2xl hover:scale-105 mx-auto">
    <div className="h-48 overflow-hidden">
      <img src={imageUrl} alt={title} className="w-full h-full object-cover" />
    </div>
    <div className="p-6">
      <div className="flex items-center mb-4">
        <div className="text-3xl text-blue-600 mr-3">{icon}</div>
        <h3 className="text-xl font-bold text-gray-800">{title}</h3>
      </div>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

const Logistics = () => {
  const healthcareAreas = [
    {
      title: "Accelerate Drug Discovery",
      description:
        "Leverage cutting-edge technologies to speed up the drug discovery process and bring life-saving treatments to market faster.",
      icon: <FaFlask />,
      imageUrl: img1,
    },
    {
      title: "Clinical Trial Efficiency",
      description:
        "Streamline clinical trials with advanced tools that enhance transparency, reduce costs, and improve participant engagement.",
      icon: <FaClipboardCheck />,
      imageUrl: img2,
    },
    {
      title: "Data-Driven Insights",
      description:
        "Utilize advanced analytics and AI to derive actionable insights that improve patient outcomes and operational efficiency.",
      icon: <FaChartLine />,
      imageUrl: img3,
    },
    {
      title: "Patient-Centric Care",
      description:
        "Enhance patient care through personalized treatment plans and innovative healthcare solutions.",
      icon: <FaUserMd />,
      imageUrl: img4,
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-green-50 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-4">
          Transforming Pharmaceuticals & Life Sciences with AeroAegis
        </h1>
        <p className="text-xl text-center text-gray-600 mb-12">
          The future of healthcare depends on innovation, speed, and precision.
          At AeroAegis, we are committed to revolutionizing the pharmaceutical
          and life sciences industry with solutions that:
        </p>
        <div className="grid grid-cols-1 w-full md:grid-cols-2 lg:grid-cols-2 xl:grid-xols-4 gap-14">
          {healthcareAreas.map((area, index) => (
            <HealthcareCard key={index} {...area} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Logistics;
