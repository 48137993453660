import { React, useState, useEffect, useLayoutEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import logo from "./assets/img/logo.png";

import "./App.css";
import Footer from "./pages/Footer";
import Header from "./pages/Header";
import Homepage from "./pages/Homepage/Homepage";

import DiscoverAeroaegis from "./pages/About us/discover_aeroaegis";
import Capabalities from "./pages/About us/aero_aegis_capabilities";
import LeadershipAndTeam from "./pages/About us/Leadership and team";
import PartnershipsPage from "./pages/About us/Partnerships/PartnershipsAndCollaboration";
import OurValuesPage from "./pages/About us/our_values";


import MS360Page from "./pages/ProductsPage/MS360";
import SysARPage from "./pages/ProductsPage/SysAR";
import SmartChaosLab from "./pages/ProductsPage/SmartChaosLab";
import InfraVuePage from "./pages/ProductsPage/InfraVue";
import AeroPulseAiPage from "./pages/ProductsPage/AeroPulseAi";
import AeroaegisIIOTPage from "./pages/ProductsPage/AeroaegisIIOT";
import AllProducts from "./pages/ProductsPage/AllProducts";
import AeroGenix from './pages/ProductsPage/AeroGenix/index.js'


import AlibabaCloud from "./pages/About us/Partnerships/PartnershipwithAlibaba/AlibabaCloud.js";
import MSAzure from "./pages/About us/Partnerships/PartnershipwithAzure/Azure.js";

import WhitePapers from "./pages/WhitePapers";
import WhitePaper1 from "./pages/Whitepapers_Detail/whitepaper1";
import WhitePaper2 from "./pages/Whitepapers_Detail/whitepaper2";
import WhitePaper3 from "./pages/Whitepapers_Detail/whitepaper3";

// import  QuantumComputing from './pages/QuantumComputing'
// import  AIAndMachineLearning from './pages/AI and Machine Learning'
// import  ARAndVR from './pages/AR and VR'
// import  IOT from './pages/Iot and Edge Computing'
// import  BlockchainTechnology from './pages/Blockchain Technology'

import CaseStudies from "./pages/CaseStudiesMainPage/index";
import Health_Case_Study from "./pages/CaseStudies/HealthCare/index.js";
import BankingCaseStudy from "./pages/CaseStudies/BankingOperations/index.js";
import FinanceCaseStudy from "./pages/CaseStudies/FinanceOperations/index.js";
import PredictiveAnalysisCaseStudy from "./pages/CaseStudies/PredictiveAnalysis/index.js";


import ContactusPage from "./pages/ContactUS/Contact";
import DisclaimerPage from "./pages/DisclaimerPage";
import TermsAndConditions from "./pages/TermsAndConditions/TnCContent";
import CsrPage from "./pages/CSR";
import CareersPage from "./pages/Careers";
import InvestorRelations from "./pages/Investor Relations";
import LocationsPage from "./pages/Locations";
import NewsPage from "./pages/News";

//Services
import AIandDataSolutions from "./pages/Services/AIandData/AIML Model Development/index.js";
import DataAnalyticsInsights from "./pages/Services/AIandData/DataAnalyticsInsights";
import DataEngineeringService from "./pages/Services/AIandData/DataEngineeringService";
import GenerativeAiSolutions from "./pages/Services/AIandData/GenerativeAiSolutions";

import CustomProductDev from "./pages/Services/ProductEngineering/CustomProductDev/index.js";
import CloudNativeProductEngineering from "./pages/Services/ProductEngineering/CloudNativeProductEngineering/index.js";
import EmbeddedProductSolutions from "./pages/Services/ProductEngineering/EmbeddedProductSolutions/index.js";
import IotEnabledProductEngineering from "./pages/Services/ProductEngineering/IotEnabledProductEngineering/index.js";
import CloudIamSolution from "./pages/Services/IAMSecurityServices/CloudIamSolutions/index.js";

import IdentidyAccessManagement from "./pages/Services/IAMSecurityServices/IdentidyandAccessManagement";
import RegulatoryCompliance from "./pages/Services/IAMSecurityServices/RegulatoryCompliance"
import PrivilegedAccessManagement from "./pages/Services/IAMSecurityServices/PrivilegedAccessManagement/index.js";
// operational excellence
import DevopsSREServicePage from "./pages/Services/OperationalExcellence/DevopsandSRE/index.js";
import ChaosEngineering from "./pages/Services/OperationalExcellence/ChaosEngineeringServices/index.js";
import PerformanceOptimization from "./pages/Services/OperationalExcellence/PerformanceOptimization/index.js";
import ManagedServicePage from "./pages/Services/OperationalExcellence/ManagedServices/index.js";

import SmartInfraDeployment from "./pages/Services/IotandEdgeSolutions/SmartInfraDeployment";
import EdgeComputingIntegration from "./pages/Services/IotandEdgeSolutions/EdgeComputing";
import PredictiveMaintainanceService from "./pages/Services/IotandEdgeSolutions/PredictiveMaintainanceService";
import EnterpriseSecurity from "./pages/CaseStudies/EnterpriseSecurity/index.js";
import DigitalTwinSolutions from "./pages/Services/IotandEdgeSolutions/DigitalTwinSolutions";
import BankingResilience from './pages/CaseStudies/BankingResilience/index.js'

import AIandDataConsulting from "./pages/Services/StrategicConsulting/AIandData/index.js";
import BlockchainAdvisoryConsulting from "./pages/Services/StrategicConsulting/BlockchainAdvisory/index.js";
import CloudMigrationConsulting from "./pages/Services/StrategicConsulting/CloudMigration/index.js";
import DigitalTransformationConsulting from "./pages/Services/StrategicConsulting/DigitalTransformationConsulting/index.js";

import System1 from "./pages/Services/SystemIntegration/EnterPriceAppl/index.js";
import System2 from "./pages/Services/SystemIntegration/CloudMigration/index.js";
import System3 from "./pages/Services/SystemIntegration/SystemRpaSolutions/index.js";
import System4 from "./pages/Services/SystemIntegration/ApiMicIntegration/index.js";

// Industries
import Healthcare from "./pages/Industries/Healthcare";
import LogisticsandSupplyChain from "./pages/Industries/LogisticsAndSupplyChain/index.js";
import TelecomandNetworks from "./pages/Industries/TelecomAndNetworks/index.js";
import Manufacturing from "./pages/Industries/Manufacturing";
import RetailEcommerce from "./pages/Industries/RetailEcommerce";
import FinanceBanking from "./pages/Industries/Finance_Banking/index";
import AeroSpace from "./pages/Industries/AeroSpace/index.js";
import Automotive from "./pages/Industries/Automotive/index.js";
import EnergyAndUtility from "./pages/Industries/EnergyAndUtility/index.js";
import Pharmaceuticals from "./pages/Industries/Pharmaceuticals/index.js";

// Engineering excellence
import RandDFocusPage from "./pages/EngineeringExcellence/RandDFocus";
import ProductEngineering from "./pages/EngineeringExcellence/ProductEngineering";
import IPDevelopment from "./pages/EngineeringExcellence/IPDevelopment";
import COE from "./pages/EngineeringExcellence/COE/index.js";

// Blogs

import Blogs from "./pages/Blogs/index.js";
import ExplainableAI from "./pages/Blogs/BlogDetailsPages/ExplainableAI.js";
import AgenticAI from "./pages/Blogs/BlogDetailsPages/AgenticAI.js";
import ZeroTrustSecurity from "./pages/Services/IAMSecurityServices/ZeroTrustSecurity/index.js";


function App() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  // Scroll to top on route change
  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, [location.pathname]);

  // Scroll Top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Handle loading state
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const haddleScroll = () => {
      // when scrollTop is 0 show id:her-pull element else hide on scroll
      const target_elm = document.getElementById("scroll-to-top");
      if (window.scrollY <= 100) {
        target_elm.classList.add("hidden");
      } else {
        target_elm.classList.remove("hidden");
      }
    };
    window.addEventListener("scroll", haddleScroll);
    return () => {
      window.removeEventListener("scroll", haddleScroll);
    };
  }, []);
  // Loading spinner
  if (isLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white z-50">
        <img
          src={logo}
          className=" animate-pulse  w-32 drop-shadow-lg"
          alt="Loading"
        />
      </div>
    );
  }

  return (
    <div className="App relative">
      <Header />
      <Routes>
        <Route path="/" Component={Homepage} />
        {/* <Route path="/" Component={HomepageV1} /> */}
        <Route path="/aeroaegis-capabilities" Component={Capabalities} />
        {/* new routes */}
        {/* <Route path="/quantum-computing" Component={QuantumComputing} />
      <Route path="/ai-and-machine-learning" Component={AIAndMachineLearning} />
      <Route path="/blockchain-technology" Component={BlockchainTechnology} />
      <Route path="/iot-edge" Component={IOT} />
      <Route path="/ar-vr" Component={ARAndVR} /> */}
        {/* Services Routes */}
        <Route
          path="/services/product-engineering/custom-product-development"
          element={<CustomProductDev />}
        />
        <Route
          path="/services/product-engineering/cloud-native-product-engineering"
          element={<CloudNativeProductEngineering />}
        />
        <Route
          path="/services/product-engineering/embedded-product-solutions"
          element={<EmbeddedProductSolutions />}
        />
        <Route
          path="/services/ai-data/ai-ml-model-development"
          element={<AIandDataSolutions />}
        />
        <Route
          path="/services/ai-data/data-analytics-insights"
          element={<DataAnalyticsInsights />}
        />
        <Route
          path="/services/ai-data/data-engineering"
          element={<DataEngineeringService />}
        />
        <Route
          path="/services/ai-data/generative-ai-solutions"
          element={<GenerativeAiSolutions />}
        />
        <Route
          path="/services/product-engineering/custom-product-development"
          element={<CustomProductDev />}
        />
        <Route
          path="/services/product-engineering/cloud-native-product-engineering"
          element={<CloudNativeProductEngineering />}
        />
        <Route
          path="/services/product-engineering/embedded-product-solutions"
          element={<EmbeddedProductSolutions />}
        />
        <Route
          path="/services/product-engineering/iot-enabled-product-engineering"
          element={<IotEnabledProductEngineering />}
        />
        <Route
          path="/services/operational-excellence/devops-and-sre"
          element={<DevopsSREServicePage />}
        />
        <Route
          path="/services/operational-excellence/chaos-engineering-solutions"
          element={<ChaosEngineering />}
        />
        <Route
          path="/services/operational-excellence/performance-optimization"
          element={<PerformanceOptimization />}
        />
        <Route
          path="/services/operational-excellence/managed-service-support"
          element={<ManagedServicePage />}
        />
        <Route
          path="/services/iot-edge-solutions/smart-infra-deployment"
          element={<SmartInfraDeployment />}
        />
        <Route
          path="/services/iot-edge-solutions/edge-computing-integration"
          element={<EdgeComputingIntegration />}
        />
        <Route
          path="/services/iot-edge-solutions/predictive-maintainance-service"
          element={<PredictiveMaintainanceService />}
        />
        <Route
          path="/services/iot-edge-solutions/digital-twin-solutions"
          element={<DigitalTwinSolutions />}
        />
        <Route
          path="/services/strategic-consulting/digital-transformation"
          element={<DigitalTransformationConsulting />}
        />
        <Route
          path="/services/strategic-consulting/ai-and-data"
          element={<AIandDataConsulting />}
        />
        <Route
          path="/services/strategic-consulting/cloud-migration"
          element={<CloudMigrationConsulting />}
        />
        <Route
          path="/services/strategic-consulting/blockchain-advisory"
          element={<BlockchainAdvisoryConsulting />}
        />
        {/* SYSTEM INTEGRATION */}
        <Route
          path="/services/system-integration/enterprise-application-integration"
          element={<System1 />}
        />
        <Route
          path="/services/system-integration/cloud-migration-integration"
          element={<System2 />}
        />
        <Route
          path="/services/system-integration/system-rpa-solutions"
          element={<System3 />}
        />
        <Route
          path="/services/system-integration/api-microservices-integration"
          element={<System4 />}
        />
        <Route
          path="/services/iam-security/cloud-iam-solutions"
          element={<CloudIamSolution />}
        />
          {/* IAM */}
        <Route
          path="/services/iam-security/identity-access-management"
          element={<IdentidyAccessManagement />}
        />
        <Route
          path="/services/iam-security/regulatory-compliance"
          element={<RegulatoryCompliance />}
        />
        <Route
          path="/services/iam-security/zero-trust-security"
          element={<ZeroTrustSecurity />}
        />
        <Route
          path="/services/iam-security/privileged-access-management"
          element={<PrivilegedAccessManagement />}
        />

        {/* Other routes */}
        {/* Services Routes */}
        {/* Industries Routes */}
        <Route path="/industries/healthcare" element={<Healthcare />} />
        <Route
          path="/industries/logistics-and-supplychain"
          element={<LogisticsandSupplyChain />}
        />
        <Route
          path="/industries/telecom-and-network"
          element={<TelecomandNetworks />}
        />
        <Route path="/industries/manufacturing" element={<Manufacturing />} />
        <Route
          path="/industries/retail-ecommerce"
          element={<RetailEcommerce />}
        />
        <Route
          path="/industries/finance-banking"
          element={<FinanceBanking />}
        />
        <Route path="/industries/aerospace" element={<AeroSpace />} />
        <Route path="/industries/automotive" element={<Automotive />} />
        <Route
          path="/industries/energy-utilities"
          Component={EnergyAndUtility}
        />
        <Route path="/industries/pharmaceuticals-life-sciences" element={<Pharmaceuticals />} />
        {/* {industries ends} */}
        {/* Engineering excellence routes */}
        <Route
          path="/engineering-excellence/research-and-development-focus"
          element={<RandDFocusPage />}
        />
        <Route
          path="/engineering-excellence/product-engineering"
          element={<ProductEngineering />}
        />
        <Route
          path="/engineering-excellence/ip-development"
          element={<IPDevelopment />}
        />
        <Route
          path="/engineering-excellence/center-of-excellence"
          element={<COE />}
        />
        {/* Engineering excellence ends here ------ */}
        <Route path="/discover-aeroaegis" Component={DiscoverAeroaegis} />
        <Route path="/leadership-team" Component={LeadershipAndTeam} />
        <Route
          path="/partnerships-collaborations"
          Component={PartnershipsPage}
        />
        <Route path="/partnerships/alibaba-cloud" Component={AlibabaCloud} />
        <Route path="/partnerships/microsoft-azure" Component={MSAzure} />
     
       
        <Route path="/our-values" Component={OurValuesPage} />
       
        <Route path="/maturity-spectrum-360" Component={MS360Page} />
        <Route path="/aeroedge-iiot" Component={AeroaegisIIOTPage} />
        <Route path='/aero-genix' element={<AeroGenix />} />
        <Route path="/sys-ar" Component={SysARPage} />
        <Route path="/products" Component={AllProducts} />
        <Route path="/smartchaos-lab" Component={SmartChaosLab} />
        <Route path="/infra-vue" Component={InfraVuePage} />
        <Route path="/aeropulse-ai" Component={AeroPulseAiPage} />
        <Route path="/contact-us" Component={ContactusPage} />
        <Route path="/white-papers" Component={WhitePapers} />
        {/* Blogs */}
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/explainable-ai" element={<ExplainableAI />} />
        <Route path="/blogs/agentic-ai" element={<AgenticAI />} />
        {/* resources */}
        <Route path="/banking-casestudy" element={<BankingCaseStudy />} />
        <Route path="/finance-casestudy" element={<FinanceCaseStudy />} />
        <Route
          path="/predictive-analysis-casestudy"
          element={<PredictiveAnalysisCaseStudy />}
        />
        <Route
          path="/enterprise-security"
          element={<EnterpriseSecurity />}
        />
        <Route
          path="/banking-resilience"
          element={<BankingResilience />}
        />
        <Route
          path="/navigating-digital-transformation-unleashing-power-maturity-spectrum-360"
          Component={WhitePaper1}
        />
        <Route
          path="/smartchaoslab-revolutionizing-application-reliability-with-chaos-engineering"
          Component={WhitePaper2}
        />
        <Route
          path="/blueprinting-tomorrows-infrastructure-today"
          Component={WhitePaper3}
        />
        <Route path="/disclaimer" Component={DisclaimerPage} />
        <Route path="/terms-conditions" Component={TermsAndConditions} />
        <Route path="/csr" Component={CsrPage} />
        <Route path="/careers" Component={CareersPage} />
        <Route path="/investor-relations" Component={InvestorRelations} />
        <Route path="/locations" Component={LocationsPage} />
        <Route path="/news-room" Component={NewsPage} />
        <Route path="/partnerships/alibaba-cloud" Component={AlibabaCloud} />
        <Route path="/health-casestudy" Component={Health_Case_Study} />
        <Route path="/case-studies" Component={CaseStudies} />
      </Routes>
      <div className="fixed bottom-8 right-8 z-50 hidden" id="scroll-to-top">
        <button
          onClick={scrollToTop}
          className="bg-[#232f7f] text-white rounded-full p-3 hover:bg-[#596191] focus:outline-none focus:shadow-outline"
          aria-label="Scroll to top"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 10l7-7m0 0l7 7m-7-7v18"
            ></path>
          </svg>
        </button>
      </div>
      <Footer />
    </div>
  );
}

export default App;
