import React from "react";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";
import { IoLockClosedOutline } from "react-icons/io5";
import { BiLike } from "react-icons/bi";
import img1 from "../../../../assets/img/Services/IAMSecuritySolutions/audit2.jpg";
import { FaRegChartBar, FaChartLine, FaFileAlt } from "react-icons/fa";

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Audit Readiness and Reporting"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Audit Readiness and Reporting
            </h1>
            <p className="text-lg text-gray-600">
              Simplify audit preparation with automated tools and real-time reporting.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaFileAlt className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Centralized Audit Trails</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Maintain immutable records of user activity, access logs, and system changes for easy auditing.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaChartLine className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Real-Time Dashboards</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Provide auditors and compliance teams with instant visibility into compliance status.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaRegChartBar className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Automated Reporting</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Generate detailed compliance reports for regulatory bodies and internal stakeholders.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
