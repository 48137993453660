import React from "react";
import { LuSettings, LuBadgeCheck, LuBrain, LuCloud } from "react-icons/lu";

export default function MainSection7() {
  const whyChooseData = [
    {
      icon: <LuSettings />,
      title: "Tailored Solutions",
      description:
        "Every organization is unique. We design Cloud IAM strategies that align with your business needs, cloud platforms, and security requirements.",
    },
    {
      icon: <LuBadgeCheck />,
      title: "Proven Expertise",
      description:
        "With experience across ForgeRock, Okta, Ping Identity, and Azure AD, our team delivers best-in-class implementations.",
    },
    {
      icon: <LuBrain />,
      title: "AI-Driven Insights",
      description:
        "Enhance your IAM capabilities with AI-powered behavioral analytics, adaptive authentication, and identity intelligence.",
    },
    {
      icon: <LuCloud />,
      title: "End-to-End Services",
      description:
        "From strategy and deployment to ongoing management, AeroAegis offers a complete suite of Cloud IAM services.",
    },
  ];

  return (
    <div className="px-4 py-4 my-auto md:px-12 md:py-12 bg-[#F7F7FC]">
      <div className="flex flex-col mb-6 pb-12 justify-center lg:justify-center lg:flex-row md:mb-8">
        <h2 className="max-w-lg font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block mb-1 sm:mb-4">Why Choose AeroAegis for Cloud IAM?</span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
        </h2>
      </div>
      <div className="flex flex-wrap justify-center gap-10">
        {whyChooseData.map((item, index) => (
          <div
            key={index}
            className="card w-[400px] border border-primary shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2"
          >
            <div className="card-body items-center text-center">
              <div className="text-4xl text-primary mb-4">{item.icon}</div>
              <h3 className="card-title text-xl mb-2">{item.title}</h3>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
