import React from "react";
import hero_img from "../../../../assets/img/Services/IAMSecuritySolutions/zero.png"; 

export default function HeroSection() {
  return (
    <div className="relative flex flex-col py-16 lg:pt-0 lg:flex-col lg:pb-0">
      <div className="inset-y-0 top-0 right-0 z-0  xl:max-w-[680px] lg:max-w-[555px]  px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0  lg:absolute xl:px-0">
      <svg
          className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
          viewBox="0 0 100 100"
          fill="currentColor"
          preserveAspectRatio="none slice"
        >
          <path d="M50 0H100L50 100H0L50 0Z" />
        </svg>
        <img
          className="object-cover w-full bg-fixed h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
          src={hero_img}
          alt="Zero-Trust Security"
        />
      </div>
      <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
        <div className="lg:mb-16 pr-14 mt-10 md:mt-10 lg:mt-10 xl:mt-14 pt-2 lg:my-20 lg:max-w-2xl ">
          <p className="p-2 inline-block px-5 text-xs font-semibold tracking-wider text-white uppercase rounded-full bg-[#232f7f]">
          </p>
          <h2 className="mb-5 text-3xl font-bold tracking-wide text-gray-900 lg:text-5xl sm:leading-none">
          Zero Trust Security Framework: Never Trust, Always Verify
          </h2>
          <p className="pr-10 lg:pr-[9rem] xl:pr-10 mb-5 text-base text-gray-700 md:text-lg">
          In today’s threat landscape, traditional security models based on perimeter defense are no longer sufficient. With the rise of cloud computing, remote work, and distributed IT infrastructures, the need for a more robust, identity-centric approach to security is critical. AeroAegis's Zero Trust Security Framework ensures that no user or device is trusted by default—whether inside or outside the corporate network. By continuously validating every access request, we help organizations protect their sensitive data, systems, and applications from evolving cyber threats.
          </p>
        </div>
      </div>
    </div>
  );
}
