import React from "react";
import hero_img from "../../../../assets/img/Products/AeroGenix/110820.jpg";
import { Link } from "react-router-dom";

export default function FinalSection() {
  return (
    <section
      className="relative text-white py-20 md:py-10 bg-cover bg-center"
      style={{
        backgroundImage: `url('${hero_img}')`,
      }}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      {/* Content */}
      <div className="container mx-auto px-4 text-center relative z-10">
        <h1 className="text-3xl md:text-4xl font-bold mb-4">
          Empower Your Security Framework with AeroAegis PAM Services
        </h1>
        <p className="text-lg mb-4">
          Take control of your organization’s privileged access with AeroAegis’s
          intelligent and secure PAM solutions. Whether you’re looking to
          modernize your security infrastructure or implement a new PAM
          strategy, AeroAegis has the expertise and technology to meet your
          needs.
        </p>
        <p className=" text-lg mb-10">
          Contact us today to explore how AeroAegis can enhance your privileged
          access management framework.
        </p>
        <Link
          to={"/contact-us"}
          className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300"
        >
          Contact Us
        </Link>
      </div>
    </section>
  );
}
