import React, { useState, useEffect } from "react";
import {
  FaHospital,
  FaPills,
  FaFileInvoiceDollar,
  FaMedkit,
} from "react-icons/fa";

import img1 from "../../../assets/img/Industries/Manufacturing/interior.avif";
import img2 from "../../../assets/img/Industries/Manufacturing/aiservice.png";
import img3 from "../../../assets/img/Industries/Manufacturing/production.avif";
import img4 from "../../../assets/img/Industries/Manufacturing/tech.avif";

const ManufacturingCard = ({ title, description, icon, imageUrl }) => (
  <div className="bg-white rounded-2xl shadow-xl w-full overflow-hidden transition-all duration-300 hover:shadow-2xl hover:scale-105 mx-auto">
    <div className="h-48 overflow-hidden">
      <img src={imageUrl} alt={title} className="w-full h-full object-cover" />
    </div>
    <div className="p-6">
      <div className="flex items-center mb-4">
        {/* <div className="text-3xl text-blue-600 mr-3">{icon}</div> */}
        <h3 className="text-xl font-bold text-gray-800">{title}</h3>
      </div>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

const EnhancedManufacturingTech = () => {
  const manufacturingAreas = [
    {
      title: "Discrete Manufacturing",
      description:
        "Modernize production lines, ensure quality control, and optimize resource utilization with IoT and AI solutions.",
      icon: <FaHospital />,
      imageUrl: img3,
    },
    {
      title: "Process Manufacturing",
      description:
        "Enhance operational efficiency in industries like chemicals, food and beverage, and pharmaceuticals with predictive analytics and automation.",
      icon: <FaPills />,
      imageUrl: img1,
    },
    {
      title: "Supply Chain Optimization",
      description:
        "Drive transparency, efficiency, and resilience across global supply chains with blockchain and advanced analytics.",
      icon: <FaFileInvoiceDollar />,
      imageUrl: img2,
    },
    {
      title: "Energy and Utilities Management",
      description:
        "Reduce energy consumption, improve asset management, and achieve sustainability goals with IoT-enabled monitoring systems.",
      icon: <FaMedkit />,
      imageUrl: img4,
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-green-50 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-4">
          Transforming Key Areas of Manufacturing
        </h1>
        <p className="text-xl text-center text-gray-600 mb-12">
          Harnessing advanced technology to redefine the future of
          manufacturing.
        </p>
        <div className="grid grid-cols-1 w-full md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-14">
          {manufacturingAreas.map((area, index) => (
            <ManufacturingCard key={index} {...area} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EnhancedManufacturingTech;
