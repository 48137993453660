import React from "react";
import { FaCogs, FaRegThumbsUp, FaShieldAlt } from "react-icons/fa";
import { AiOutlineDeploymentUnit } from "react-icons/ai";
import { MdOutlineEngineering, MdOutlineVisibility } from "react-icons/md";
import { BiAnalyse } from "react-icons/bi";
import { IoShieldCheckmark } from "react-icons/io5";
import { CiCircleCheck, CiCloud } from 'react-icons/ci';

export default function KeyBenefits() {
  const benefits = [
    {
      icon: <FaShieldAlt />,
      title: "Enhanced Security Posture",
      description:
        "Mitigate risks by eliminating implicit trust and validating every access request.",
    },
    {
      icon: <FaRegThumbsUp />,
      title: "Improved User Experience",
      description:
        "Streamline access with seamless integrations like SSO and MFA, ensuring security without disrupting workflows.",
    },
    {
      icon: <IoShieldCheckmark />,
      title: "Comprehensive Threat Protection",
      description:
        "Detect and mitigate threats in real time with AI-driven analytics and continuous monitoring.",
    },
    {
      icon: <CiCircleCheck />,
      title: "Regulatory Compliance",
      description:
        "Simplify adherence to industry regulations with robust access controls and audit capabilities.",
    },
    {
      icon: <CiCloud />,
      title: "Cloud-Native Compatibility",
      description:
        "Secure assets in multi-cloud and hybrid environments with scalable and adaptive Zero Trust policies.",
    },
  ];

  return (
    <section className="py-16 bg-base-100">
        <div className="mt-6 px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-14 lg:px-8 lg:py-5">
        <div className="flex flex-col lg:flex-row justify-between lg:text-left my-2">
          <h2 className="font-sans text-2xl font-bold tracking-tight text-gray-900 md:text-3xl sm:text-2xl sm:leading-none mb-5 group">
            <span className="inline-block mb-1 sm:mb-4">Key Benefits of AeroAegis Zero Trust Security</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
          <p className="text-gray-700 font-semibold lg:text-xl lg:max-w-md italic">
            "AeroAegis provides cutting-edge solutions to simplify compliance, enhance audit readiness, and build trust."
          </p>
        </div>
        <div className="flex flex-wrap justify-center gap-8">
          {benefits.map((benefit, index) => (
            <div
              key={index}
              className="card border border-primary w-[450px] shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2"
            >
              <div className="card-body items-center text-center">
                <div className="text-4xl text-primary mb-4">{benefit.icon}</div>
                <h3 className="card-title text-xl mb-2">{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
