import React from "react";
import { BsBank2 } from "react-icons/bs"; // Finance & Banking
import { MdHealthAndSafety } from "react-icons/md"; // Healthcare
import { FaBolt, FaShoppingCart } from "react-icons/fa"; // Energy & Retail

const IndustriesWeServe = () => {
  return (
    <div className="bg-gray-100 py-10">
      <section className="py-5">
          <div className="container mx-auto px-4">
          <div className="flex flex-col mb-6 md:items-start lg:items-center justify-center lg:justify-center lg:flex-row md:mb-8">
            <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group md:text-left lg:text-center text-left sm:text-left">
              <span className="inline-block mb-1 sm:mb-4 md:mb-2 md:text-left lg:text-center">
                Industries We Serve
              </span>
              <div className="h-1 md:ml-0 lg:ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
              <p className="text-lg text-gray-600 md:text-left lg:text-center">
                Our Cloud IAM solutions cater to a wide range of industries, including:
              </p>
            </h2>
          </div>
          <div className="grid justify-items-center grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mb-8">
            <FeatureCard
              icon={<BsBank2 className="text-4xl text-blue-600" />}
              title="Finance & Banking"
              description="Secure customer data, prevent fraud, and ensure regulatory compliance in hybrid cloud environments."
            />
            <FeatureCard
              icon={<MdHealthAndSafety className="text-4xl text-blue-600" />}
              title="Healthcare"
              description="Protect sensitive patient records and simplify HIPAA compliance with strong cloud access controls."
            />
            <FeatureCard
              icon={<FaBolt className="text-4xl text-blue-600" />}
              title="Energy & Utilities"
              description="Secure critical infrastructure and ensure compliance with stringent industry standards."
            />
            <FeatureCard
              icon={<FaShoppingCart className="text-4xl text-blue-600" />}
              title="Retail & E-Commerce"
              description="Safeguard customer data and provide secure, seamless access for employees and partners."
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export function FeatureCard({ icon, title, description, className }) {
  return (
    <div
      className={`card bg-white p-6 rounded-lg shadow-md max-w-[400px]
    ${className ? className : ""}
    `}
    >
      <div className="flex items-center justify-center mb-4">{icon}</div>
      <h3 className="text-xl font-semibold mb-2 text-center">{title}</h3>
      <p className="text-gray-600 text-center">{description}</p>
    </div>
  );
}

export default IndustriesWeServe;
