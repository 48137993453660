import React from "react";
import { FaBalanceScale, FaShieldAlt, FaClipboardCheck, FaHandshake, FaRegHandshake } from 'react-icons/fa';
import { FiThumbsUp } from "react-icons/fi";

export default function MainSection1() {
  return (
    <div>
      <div className="mt-6 md:px-16 px-4  py-4 mx-auto xl:px-24 lg:py-20">
      <div className="mt-16">
        <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
          <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">Key Benefits</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
          <p className="text-gray-700 font-semibold lg:text-xl lg:max-w-md italic">
          "Ensure compliance, mitigate risks, and build trust with our regulatory solutions."
          </p>

        </div>

        <section className="py-4 bg-base-100">
          <div className="container mx-auto px-4">
            <div className=" flex flex-wrap gap-8 justify-center items-stretch">
              {[
                { icon:<FaShieldAlt />, title: "Regulatory Compliance", description: "Protect sensitive data and intellectual property." },
                { icon: <FiThumbsUp />, title: "Data Security & Privacy", description: "Simplify regulatory reporting and reduce administrative burdens." },
                { icon: <FaRegHandshake />, title: "Automated Audits", description: "Strengthen trust with customers, partners, and regulators." },
                // { icon: <FaHandshake />, title: "Trust & Transparency", description: "Strengthen relationships with stakeholders through compliance assurance." }
              ].map((item, index) => (
                <div key={index} className= "card border  h-full w-[300px] lg:col-span-1 border-primary shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2">
                  <div className="card-body flex flex-col justify-between items-center text-center h-full">
                    <div className="text-4xl text-primary mb-4">{item.icon}</div>
                    {/* <h3 className="card-title text-xl mb-2 min-h-[60px]">{item.title}</h3> */}
                    <p className="min-h-[70px]">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
      </div>
    </div>
  );
}
