import img3 from "../../../../assets/img/Products/AeroGenix/deploy.svg";
import img1 from "../../../../assets/img/Products/AeroGenix/Data points-rafiki.svg";
import img4 from "../../../../assets/img/Products/AeroGenix/monitoring.svg";
import img2 from "../../../../assets/img/Products/AeroGenix/people creating robot-rafiki.svg";

export default function PAMImplementationServices() {
  const steps = [
    {
      number: "1",
      title: "PAM Strategy and Assessment",
      description: [
        "Conduct a comprehensive assessment of your organization’s privileged account landscape.",
        "Identify gaps and design a tailored roadmap to strengthen your privileged access security.",
      ],
      image: img1,
    },
    {
      number: "2",
      title: "PAM System Design and Deployment",
      description: [
        "Implement leading PAM tools such as CyberArk, BeyondTrust, or Thycotic to address your specific requirements.",
        "Integrate PAM solutions seamlessly with your existing IT infrastructure.",
      ],

      image: img2,
    },
    {
      number: "3",
      title: "Privileged Account Discovery and Onboarding",
      description: [
        "Identify and onboard all privileged accounts, including system, application, and domain accounts.",
        "Ensure complete visibility and control over privileged access points.",
      ],

      image: img3,
    },
    {
      number: "4",
      title: "Compliance and Audit Automation",
      description: [
        "Automate compliance reporting for regulations like GDPR, HIPAA, and PCI DSS.",
        "Provide detailed audit trails of privileged activity for regulatory reviews and investigations.",
      ],

      image: img4,
    },
    {
      number: "4",
      title: "Managed PAM Services",
      description: [
        "Continuously monitor and optimize your PAM implementation with AeroAegis's managed services.",
        "Receive 24/7 support to address evolving security threats and operational challenges.",
      ],

      image: img4,
    },
  ];

  return (
    <div className="px-4 py-16 sm:px-6 lg:px-8 bg-white">
     <div className="flex flex-col mb-6 pb-12 justify-center lg:justify-center lg:flex-row md:mb-8">
      <h2 className="max-w-lg font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
        <span className="inline-block mb-1 sm:mb-4">Our PAM Implementation Services</span>
        <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
      </h2>
    </div>

      <div className="flex flex-wrap justify-center gap-8 bg-white">
        {steps.map((step) => (
          <div
            key={step.number}
            className="bg-white max-w-[450px] xl:max-w-[550px] rounded-lg p-8  shadow-xl hover:-translate-y-1 hover:shadow-2xl  transition-all duration-300 border border-primary"
          >
            <div className="flex flex-col justify-between items-start gap-6">
              <div className="space-y-4">
                <div className="flex-shrink-0">
                  <img
                    src={step.image}
                    alt={`Step ${step.number} - ${step.title}`}
                    className="w-32 h-32 mx-auto object-contain"
                  />
                </div>
                <h3 className="text-xl font-semibold text-gray-900">
                  {step.title}
                </h3>
                <ul className="text-gray-500 list-disc	pl-5 ">
                  <li className="m-2">{step.description[0]}</li>
                  <li className="m-2">{step.description[1]}</li>
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}