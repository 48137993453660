import React from "react";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";
import { IoLockClosedOutline } from "react-icons/io5";
import { BiLike } from "react-icons/bi";
import img1 from "../../../../assets/img/Services/IAMSecuritySolutions/MultiFactorAuth.jpg";
import { FaCalendarAlt, FaCompass, FaHeartbeat, FaPaintBrush, FaReact, FaRegChartBar, FaUserAlt } from "react-icons/fa";
import { GoGraph } from "react-icons/go";

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Multi-Factor Authentication (MFA)"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Multi-Factor Authentication (MFA)
            </h1>
            <p className="text-lg text-gray-600">
              Add an extra layer of protection with MFA, combining something you know (password) with something you have (token) or something you are (biometrics).
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaUserAlt className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">MFA Methods</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Deploy MFA methods such as biometrics, hardware tokens, SMS codes, and mobile app authentication.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaCompass className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Adaptive Authentication</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Use adaptive authentication to adjust security based on user behavior, location, and device.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
