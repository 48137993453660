import React from 'react'
import AnimationRevealPage from "../../../../helpers/AnimationRevealPage";
import MainSection2 from './MainSection2'
import MainSection1 from './MainSection1'
import MainSection3 from './MainSection3'
import MainSection4 from './MainSection4'
import MainSection5 from './MainSection5'
import MainSection6 from './MainSection6'
import MainSection7 from './MainSection7'
import MainSection8 from './MainSection8'
import IndustriesWeServe from './IndustriesWeServe';
import KeyBenefits from './keybenefits';
import MainSection0 from './MainSection0';

import FinalSection from './FinalSection'
import HeroSection from './HeroSection'
import Content1 from './Content1';
import Tech from './Tech'
import WhyChooseaero from './WhyChooseaero';

export default function CustomProductDev() {
  return (
    <div>
      <HeroSection/>

      {/* <Content1/> */}

        <AnimationRevealPage>
        {/* <MainSection2/> */}
        <MainSection1/>
        <MainSection3/>
        <MainSection4/>
        <MainSection5/>
        <MainSection6/>
        <MainSection7/>
        <MainSection8/>
        <Tech />
        <WhyChooseaero />
        {/* <IndustriesWeServe /> */}
        {/* <KeyBenefits /> */}
        <MainSection0 />
        <FinalSection/>
        </AnimationRevealPage>
    </div>
  )
}
