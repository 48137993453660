import React from 'react';
import { Link } from 'react-router-dom';
import finance_case_study from '../../assets/img/banking.jpg';
import { useNavigate } from 'react-router-dom';
import {
  HiOutlineCog,
  HiOutlineArrowRight,
} from 'react-icons/hi';
import { FaChartBar, FaRegCheckCircle } from 'react-icons/fa';

export default function Section6() {
  const navigate = useNavigate();

  return (
    <div className="w-full  p-6 ">
      <div className="bg-white border border-gray-200 h-full rounded-xl shadow-lg overflow-hidden">
        <div>
          <div>
            <img
              src={finance_case_study}
              alt="Finance Case Study"
              className="w-full h-52 object-cover"
            />
          </div>
          <div className="p-8">
            <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
              Case Study
            </div>
            <h2 className="mt-2 text-xl font-bold text-gray-900 dark:text-white">
              Enhancing Banking Resilience with Big Data, Chaos Engineering, and
              DevOps
            </h2>
            <p className="mt-2 text-gray-600 dark:text-gray-300">
              AeroAegis Enables a Leading Bank to Achieve Reliability,
              Resilience, and SLA Optimization with SmartChaosLab
            </p>
            <div className="mt-4 flex flex-wrap gap-4">
              <span className="inline-flex items-center rounded-md bg-blue-50 dark:bg-blue-900/20 px-2 py-1 text-xs font-medium text-blue-700 dark:text-blue-300 ring-1 ring-inset ring-blue-700/10 dark:ring-blue-300/20">
                <FaChartBar className="mr-1 h-3 w-3" />
                Big Data & Advanced Analytics
              </span>
              <span className="inline-flex items-center rounded-md bg-green-50 dark:bg-green-900/20 px-2 py-1 text-xs font-medium text-green-700 dark:text-green-300 ring-1 ring-inset ring-green-600/20 dark:ring-green-300/20">
                <HiOutlineCog className="mr-1 h-3 w-3" />
                Chaos Engineering for Banking Operation
              </span>
              <span className="inline-flex items-center rounded-md bg-yellow-50 dark:bg-yellow-900/20 px-2 py-1 text-xs font-medium text-yellow-800 dark:text-yellow-300 ring-1 ring-inset ring-yellow-600/20 dark:ring-yellow-300/20">
                <FaRegCheckCircle className="mr-1 h-3 w-3" />
                DevOps-Driven Reliability
              </span>
              <span className="inline-flex items-center rounded-md bg-red-50 dark:bg-red-900/20 px-2 py-1 text-xs font-medium text-red-700 dark:text-red-300 ring-1 ring-inset ring-red-600/20 dark:ring-red-300/20">
                <FaChartBar className="mr-1 h-3 w-3" />
                SLA & Business Impact Measurement
              </span>
            </div>

            <div className="mt-6">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Key Outcomes:
              </h3>
              <ul className="mt-2 list-disc list-inside text-gray-600 dark:text-gray-300">
                <li>
                  Proactive failure detection and risk mitigation&nbsp;in banking
                  infrastructure
                </li>
                <li>
                  Automated SLA monitoring&nbsp;with real-time business impact
                  assessment.
                </li>
                <li>
                  Enhanced system reliability and resilience scoring&nbsp;using
                  SmartChaosLab.
                </li>
                <li>
                  DevOps-driven chaos testing&nbsp;to improve recovery strategies and
                  performance.
                </li>
              </ul>
            </div>
            <div className="mt-6 flex justify-center">
              <Link
                to="/banking-resilience"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Read Full Case Study
                <HiOutlineArrowRight className="ml-2 -mr-1 h-4 w-4" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}