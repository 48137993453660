import React from 'react';
import AnimationRevealPage from '../../../../helpers/AnimationRevealPage';
import MainSection1 from './MainSection1';
import MainSection2 from './MainSection2'; 
import MainSection3 from './MainSection3';
import MainSection4 from './MainSection4';
import MainSection5 from './MainSection5';
import FinalSection from './FinalSection';
import HeroSection from './HeroSection';
import MainSection6 from './MainSection6';
import MainSection from './MainSection';
import IndustriesWeServe from './IndustriesWeServe';
import MainSection8 from './MainSection8';
import KeyBenefits from './keybenefits';
import Tech from './Tech';
// import MainSection9 from './MainSection9';

export default function ZeroTrustSecurity() {
  return (
    <div>
      <HeroSection />
      <AnimationRevealPage>
        <MainSection1 />
        <MainSection />
        <MainSection2 /> 
        <MainSection3 />
        <MainSection4 />
        <MainSection5 />
        <Tech />
        {/* <MainSection9 /> */}
        <IndustriesWeServe />
        <MainSection8 />
        <KeyBenefits />
        <FinalSection />
        {/* <MainSection6 /> */}
      </AnimationRevealPage>
    </div>
  );
}
