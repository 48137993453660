import React from "react";
import { FaEye } from "react-icons/fa";
import { LuShieldCheck, LuBadgeCheck } from "react-icons/lu";

export default function MainSection1() {
  return (
    <div>
      <div className="mt-6 px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-5">
        <div className="flex my-2 lg:justify-between lg:flex-row flex-col">
          <h2 className="font-sans text-2xl font-bold tracking-tight text-gray-900 md:text-3xl sm:text-2xl sm:leading-none mb-5 group">
            <span className="inline-block mb-1 sm:mb-4">Key Benefits</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
          <p className="text-gray-700 font-semibold lg:text-xl lg:max-w-md italic">
            "Enhanced visibility and reduced risk with our comprehensive PAM
            solutions."
          </p>
        </div>

        <section className="py-4 bg-base-100">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 place-items-center">
              {[
                {
                  icon: <LuShieldCheck />,
                  title: "Reduced Risk",
                  description:
                    "Minimize the attack surface by restricting access to privileged accounts.",
                },
                {
                  icon: <FaEye />,
                  title: "Enhanced Visibility",
                  description:
                    "Gain real-time insights into privileged activity with comprehensive monitoring and reporting.",
                },
                {
                  icon: <LuBadgeCheck />,
                  title: "Regulatory Compliance",
                  description:
                    "Meet global standards like GDPR, PCI DSS, and HIPAA through automated access controls and audit trails.",
                },
                // {
                //   icon: <MdOutlineBuild />,
                //   title: "Automation",
                //   description:
                //     "Continuously improve AI outcomes with automated monitoring and MLOps.",
                // },
              ].map((item, index) => (
                <div
                  key={index}
                  className={`${ index === 2 ? 'md:col-span-2 md:w-full' : '' } card border  h-full lg:w-[300px] lg:col-span-1 border-primary shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2`}
                >
                  <div className="card-body items-center text-center">
                    <div className="text-4xl text-primary mb-4">
                      {item.icon}
                    </div>
                    <h3 className="card-title text-xl mb-2">{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}