import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight, FaChartBar, FaCloud, FaShieldAlt } from 'react-icons/fa';
import health_care_case_study from '../../assets/img/health_care_case_study.jpg';

export default function Section1() {
  return (
    <div className="w-full p-6">
      <div className="bg-white border border-gray-200 h-full dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden flex flex-col">
        {/* Image Section */}
        <img
          src={health_care_case_study}
          alt="Healthcare Case Study"
          className="w-full h-52 object-cover"
        />

        {/* Content Section */}
        <div className="p-8 flex-grow flex flex-col justify-between">
          <div>
            <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
              Case Study
            </div>
            <h2 className="mt-2 text-xl font-bold text-gray-900 dark:text-white">
              AeroAegis Inc. Revolutionizes Healthcare with Cloud-Native
              Solutions
            </h2>
            <p className="mt-2 text-gray-600 dark:text-gray-300">
              Leading healthcare organization achieves digital transformation
              through AeroAegis Inc.'s expertise in cloud-native architecture,
              event-driven systems, and big data analytics.
            </p>

            {/* Tags */}
            <div className="mt-4 flex flex-wrap gap-4">
              <span className="inline-flex items-center rounded-md bg-blue-50 dark:bg-blue-900/20 px-2 py-1 text-xs font-medium text-blue-700 dark:text-blue-300 ring-1 ring-inset ring-blue-700/10 dark:ring-blue-300/20">
                <FaCloud className="mr-1 h-3 w-3" /> Cloud-Native
              </span>
              <span className="inline-flex items-center rounded-md bg-green-50 dark:bg-green-900/20 px-2 py-1 text-xs font-medium text-green-700 dark:text-green-300 ring-1 ring-inset ring-green-600/20 dark:ring-green-300/20">
                <FaChartBar className="mr-1 h-3 w-3" /> Big Data
              </span>
              <span className="inline-flex items-center rounded-md bg-yellow-50 dark:bg-yellow-900/20 px-2 py-1 text-xs font-medium text-yellow-800 dark:text-yellow-300 ring-1 ring-inset ring-yellow-600/20 dark:ring-yellow-300/20">
                <FaShieldAlt className="mr-1 h-3 w-3" /> Healthcare
              </span>
            </div>

            {/* Key Outcomes */}
            <div className="mt-6">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Key Outcomes:
              </h3>
              <ul className="mt-2 list-disc list-inside text-gray-600 dark:text-gray-300">
                <li>Enhanced system performance and scalability</li>
                <li>Real-time data processing and insights</li>
                <li>Improved patient care and outcomes</li>
                <li>Strengthened regulatory compliance and security</li>
              </ul>
            </div>
          </div>

          {/* Button - Always Stays at the Bottom */}
          <div className="mt-auto pt-6 flex justify-center">
            <Link
              to="/health-casestudy"
              className="inline-flex items-center mt-auto px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Read Full Case Study
              <FaArrowRight className="ml-2 -mr-1 h-4 w-4" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
