import React from "react";

import { AiOutlineIssuesClose } from "react-icons/ai";
import { FaBoxesPacking } from "react-icons/fa6";
import { LuRocket } from "react-icons/lu";

import { IoIosTimer } from "react-icons/io";


export default function MainSection1() {
  return ( 
    <div>
      <div className="mt-6 px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
          <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">Key Benefits</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
          <p className="text-gray-700 font-semibold lg:text-2xl lg:max-w-md italic">
            "Use IoT and AI
            <span className=" ml-1"> to identify and resolve system failures before they occur."</span>
          </p>
        </div>

      <section className="py-4 bg-base-100">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              { icon: < IoIosTimer />, title: "Downtime", description: "Reduce unplanned downtime and maintenance costs." },
              { icon: <AiOutlineIssuesClose />, title: "Lifespan", description: "Extend the lifespan of equipment with proactive interventions." },
              { icon: <LuRocket />, title: "Efficiency",  description: "Increase operational efficiency with automated maintenance workflows." },
              { icon: <FaBoxesPacking />, title: "Performance", description: "Improve reliability and customer satisfaction with optimized asset performance." },
            ].map((item, index) => (
              <div key={index} className="card border border-primary shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2">
                <div className="card-body items-center text-center">
                  <div className="text-4xl text-primary mb-4">{item.icon}</div>
                  <h3 className="card-title text-xl mb-2">{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      </div>
    </div>
  );
}
