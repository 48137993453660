import React from "react";
import { FaSolarPanel, FaLightbulb, FaUserCog, FaLeaf } from "react-icons/fa";

import img1 from "../../../assets/img/Industries/Energy/optimised.jpg";
import img2 from "../../../assets/img/Industries/Energy/Hi-Tech-Energy-Monitoring-System.jpg";
import img4 from "../../../assets/img/Industries/Energy/experience.jpg";
import img3 from "../../../assets/img/Industries/Energy/sustain.jpeg";

const EnergyCard = ({ title, description, icon, imageUrl }) => (
  <div className="bg-white rounded-2xl shadow-xl w-full overflow-hidden transition-all duration-300 hover:shadow-2xl hover:scale-105 mx-auto">
    <div className="h-48 overflow-hidden">
      <img src={imageUrl} alt={title} className="w-full h-full object-cover" />
    </div>
    <div className="p-5">
      <div className="flex items-center mb-3">
        {/* <div className="text-3xl text-blue-600 mr-3">{icon}</div> */}
        <h3 className="text-xl font-bold text-gray-800">{title}</h3>
      </div>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

const Energy = () => {
  const energyAreas = [
    {
      title: "Optimize Operations",
      description:
        "Streamline energy production and distribution processes for maximum efficiency and reliability.",
      icon: <FaSolarPanel />,
      imageUrl: img1,
    },
    {
      title: "Enhance Transparency",
      description:
        "Implement advanced monitoring and reporting systems to ensure clear and accurate data visibility.",
      icon: <FaLightbulb />,
      imageUrl: img2,
    },
    {
      title: "Customer Experiences",
      description:
        "Deliver personalized and responsive services to meet the evolving needs of energy consumers.",
      icon: <FaUserCog />,
      imageUrl: img4,
    },
    {
      title: "Drive Sustainability",
      description:
        "Promote renewable energy sources and eco-friendly practices to achieve long-term environmental goals.",
      icon: <FaLeaf />,
      imageUrl: img3,
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-green-50 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-4">
          Transforming Energy with AeroAegis
        </h1>
        <p className="text-xl text-center text-gray-600 mb-12">
          Partner with AeroAegis to redefine the future of energy and utilities.
          Our innovative solutions empower organizations to:
        </p>
        <div className="grid grid-cols-1 w-full md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-14">
          {energyAreas.map((area, index) => (
            <EnergyCard key={index} {...area} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Energy;
