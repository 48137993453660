import React from "react";
import { FaShieldAlt, FaUserLock, FaKey, FaCogs } from 'react-icons/fa';

export default function MainSection1() {
  return (
    <div>
      <div className="mt-6 items-start lg:px-16 px-4 py-16 mx-auto w-full md:max-w-screen-md lg:max-w-screen-2xl lg:py-20">
        <div className="flex flex-col lg:flex-row justify-between lg:text-left mb-6 md:mb-8 my-2">
          <h2 className="max-w-lg font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none mb-5 md:mb-0 group">
            <span className="inline-block mb-1 sm:mb-4">Key Benefits</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
          <p className="text-gray-700 font-semibold lg:text-xl lg:max-w-md italic">
            "Enhance security and simplify access management with our comprehensive IAM solutions."
          </p>
        </div>

        <section className="py-4 bg-base-100">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-6">
              {[
                { icon: <FaShieldAlt />, title: "Enhanced Security", description: "Minimize unauthorized access and protect against insider threats and external breaches." },
                { icon: <FaUserLock />, title: "Streamlined User Experience", description: "Provide seamless access with Single Sign-On (SSO) and Multi-Factor Authentication (MFA)." },
                { icon: <FaKey />, title: "Regulatory Compliance", description: "Meet stringent requirements like GDPR, HIPAA, and PCI DSS with ease." },
                { icon: <FaCogs />, title: "Operational Efficiency", description: "Automate provisioning, de-provisioning, and access governance to reduce IT overhead." }
              ].map((item, index) => (
                <div key={index} className="card border border-primary shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2 w-full">
                  <div className="card-body flex flex-col justify-between items-center text-center h-full">
                    <div className="text-4xl text-primary mb-4">{item.icon}</div>
                    <h3 className="card-title text-xl mb-2 min-h-[60px]">{item.title}</h3>
                    <p className="min-h-[70px]">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
