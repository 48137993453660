import React from "react";
import { MdAdminPanelSettings, MdOutlineSecurity, MdOutlineGppGood } from "react-icons/md";
import { RiUserSharedLine } from "react-icons/ri";

export default function MainSection6() {
  return (
    <div>
      <div className="mt-6 px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col lg:flex-row justify-between lg:text-left my-2">
          <h2 className="font-sans text-2xl font-bold tracking-tight text-gray-900 md:text-3xl sm:text-2xl sm:leading-none mb-5 group">
            <span className="inline-block mb-1 sm:mb-4">Why Cloud IAM is Essential</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
          <p className="text-gray-700 font-semibold lg:text-xl lg:max-w-md italic">
          "Managing identities across cloud environments is complex—Cloud IAM ensures security, efficiency, and compliance."
          </p>
        </div>

        <section className="py-4 bg-base-100">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[
                {
                  icon: <RiUserSharedLine />,
                  title: "Fragmented Identity Management",
                  description:
                    "Lack of centralized control over multiple platforms and services.",
                },
                {
                  icon: <MdOutlineSecurity />,
                  title: "Security Vulnerabilities",
                  description:
                    "Increased risk of unauthorized access, insider threats, and credential theft.",
                },
                {
                  icon: <MdOutlineGppGood />,
                  title: "Compliance Challenges",
                  description:
                    "Difficulty adhering to regulations like GDPR, HIPAA, and PCI DSS in decentralized systems.",
                },
              ].map((item, index) => (
                <div
                  key={index}
                  className="card border border-primary shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2"
                >
                  <div className="card-body items-center text-center">
                    <div className="text-4xl text-primary mb-4">{item.icon}</div>
                    <h3 className="card-title text-xl mb-2">{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}