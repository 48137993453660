import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";
import { IoLockClosedOutline } from "react-icons/io5";
import { MdOutlineAutorenew } from "react-icons/md"; // Streamlined Model Lifecycle Management
import { MdOutlineTrendingUp } from "react-icons/md"; // Continuous Model Improvement
import { MdOutlineGavel } from "react-icons/md";
import { IoSearch } from "react-icons/io5";
import { LuShield } from "react-icons/lu";
import { LuLayoutDashboard } from "react-icons/lu";
import img1 from "../../../../assets/img/Services/IAMSecuritySolutions/cyberAttack.webp";

export default function MainSection5() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Privileged Threat Analytics
            </h1>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <IoSearch className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Real-Time Threat Detection
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Identify high-risk behaviors and unusual patterns in privileged
                account activity with AI-driven analytics.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <LuShield className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Incident Response Automation
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Automate responses to detected threats, such as revoking access
                or initiating multi-factor authentication (MFA) challenges.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <LuLayoutDashboard className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Dashboard Insights
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Access actionable insights through real-time dashboards for
                effective decision-making and proactive risk mitigation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
