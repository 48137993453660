import React from "react";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";
import { IoLockClosedOutline } from "react-icons/io5";
import { BiLike } from "react-icons/bi";
import img1 from "../../../../assets/img/Services/IAMSecuritySolutions/industrySpecific.png";
import { FaUniversity, FaHeartbeat, FaShoppingCart, FaIndustry } from "react-icons/fa";

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Industry-Specific Compliance Solutions
            </h1>
            <p className="text-lg text-gray-600">
              AeroAegis tailors compliance solutions to address the unique challenges of various industries.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaUniversity className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Finance & Banking</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Meet requirements for anti-money laundering (AML), Know Your Customer (KYC), and PCI DSS.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaHeartbeat className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Healthcare</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Ensure HIPAA compliance and secure patient data with robust privacy controls.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaShoppingCart className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Retail & E-Commerce</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Protect customer payment information and maintain PCI DSS compliance.
              </p>
            </div>

            {/* Feature 4 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaIndustry className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Energy & Utilities</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Secure critical infrastructure and comply with NERC CIP and other industry-specific standards.
              </p>
            </div>
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Industry-Specific Compliance Solutions"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}
