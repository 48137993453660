import React from "react";
import {
  FaRocket,
  FaChartLine,
  FaCog,
  FaIndustry,
  FaBrain,
  FaDatabase,
  FaShieldAlt,
  FaUserLock,
  FaKey,
  FaCogs,
  FaUserAlt,
  FaSync,
  FaCompass,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const AeroAegisLanding = () => {
  return (
    <div className="bg-gray-100">
      {/* Hero Section */}
      <section className="bg-blue-600 text-white py-20">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
            Empower Your Business with AeroAegis IAM Services
          </h1>
          <p className="text-xl mb-8">
          With AeroAegis, securing your enterprise is simple, scalable, and intelligent. Whether you’re modernizing your infrastructure or implementing a new IAM strategy, we’re here to help you achieve your security and compliance goals while delivering seamless user experiences. Visit www.aeroaegis.com or connect with our IAM experts for a personalized consultation. Together, we’ll build a secure future for your business.
          </p>
          <Link
            to={"/contact-us"}
            className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300"
          >
            Contact Us
          </Link>
        </div>
      </section>

    </div>
  );
};

export default AeroAegisLanding;
