import React from "react";
import { FaBrain, FaCog, FaIndustry, FaRocket } from "react-icons/fa";
import {
  BsGraphUp,
  BsPerson,
  BsArrowRepeat,
  BsCompass,
  BsDatabase,
  BsArrowRight,
} from "react-icons/bs";
import { GiSparkles } from "react-icons/gi";

const TechPartnerCard = ({ icon, title, description }) => (
  <div className="group relative p-8 bg-white rounded-2xl hover:shadow-2xl transition-all duration-500 border border-gray-100 overflow-hidden shadow-lg">
    <div className="absolute inset-0 bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
    <div className="absolute top-0 right-0 w-32 h-32 bg-blue-100 rounded-full blur-3xl opacity-20 -mr-16 -mt-16" />
    <div className="relative">
      <div className="flex items-start space-x-4 mb-6">
        <div className="p-3 bg-gradient-to-br from-blue-500 to-indigo-600 rounded-xl text-white shadow-lg">
          {icon}
        </div>
        <h3 className="text-xl font-bold text-gray-800 group-hover:text-blue-700 transition-colors duration-300">
          {title}
        </h3>
      </div>
      <p className="text-gray-600 leading-relaxed">{description}</p>
      <div className="absolute top-2 right-2">
        <GiSparkles className="w-6 h-6 text-blue-300 opacity-60" />
      </div>
    </div>
  </div>
);


const TechnologyPartnersSection = () => {
  const partners = [
    {
      icon: <FaBrain className="w-6 h-6" />,
      title: "ForgeRock",
      description:
        "A complete identity platform for managing hybrid and multi-cloud environments with advanced AI capabilities.",
    },
    {
      icon: <FaCog className="w-6 h-6" />,
      title: "Okta",
      description:
        "Cloud-first identity solutions offering SSO, MFA, and lifecycle management for seamless cloud integration.",
    },
    {
      icon: <FaIndustry className="w-6 h-6" />,
      title: "Ping Identity",
      description:
        "Secure identity federation and access control for hybrid cloud infrastructures.",
    },
    {
      icon: <FaRocket className="w-6 h-6" />,
      title: "Microsoft Azure AD",
      description:
        "Enterprise-grade cloud IAM with deep integration into Microsoft services, ideal for hybrid environments.",
    },
  ];


  return (
    <>
      <section className="py-24 bg-white relative overflow-hidden">
        <div className="absolute inset-0 bg-grid-gray-100 opacity-20" />
        <div className="absolute inset-0 bg-white" />
        <div className="container mx-auto px-4 relative">
          <div className="max-w-3xl mx-auto text-center mb-20">
            <h2 className="text-5xl font-bold text-gray-900 mb-6 leading-tight">
              Our Technology Partners 
            </h2>
            <p className="text-xl text-gray-600 leading-relaxed">
            Our Cloud IAM solutions are powered by leading technologies, ensuring robust security and seamless integration
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {partners.map((partner, index) => (
              <TechPartnerCard key={index} {...partner} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default TechnologyPartnersSection;