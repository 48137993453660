import React from "react";
import {
  MdOutlineVerifiedUser,
  MdOutlineShield,
  MdOutlineAnalytics,
  MdOutlinePolicy,
} from "react-icons/md";
import { LuCloud } from "react-icons/lu";
import img1 from "../../../../assets/img/Services/IAMSecuritySolutions/zero-trust-implementation.jpg"; // Ensure correct image path

export default function MainSection4() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse grid-cols-1 md:grid-cols-2 gap-8 items-center">
        {/* Content Section */}
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Zero Trust Implementation"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Zero Trust Data Protection
            </h1>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 - Identity-Centric Security & Verification */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineVerifiedUser className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Secure Data Access
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                &nbsp;Protect sensitive data with encryption and granular access
                controls that prevent unauthorized sharing or downloads.
              </p>
            </div>

            {/* Feature 2 - Dynamic Policy-Based Access Controls */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <LuCloud className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Cloud Data Security
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Safeguard data across public, private, and hybrid cloud
                environments with adaptive policies.
              </p>
            </div>

            {/* Feature 3 - AI-Driven Security Insights & Monitoring */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineAnalytics className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Immutable Audit Trails
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Maintain detailed logs of all user and system activities to
                ensure compliance and enable forensic analysis.
              </p>
            </div>

            {/* Feature 4 - Compliance & Governance Automation */}
            {/* <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineShield className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Compliance & Governance
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Maintain audit trails, automated compliance reporting, and
                regulatory adherence (GDPR, HIPAA, PCI DSS, NIST 800-207).
              </p>
            </div> */}
          </div>
        </div>

        {/* Image Section */}
      </div>
    </div>
  );
}