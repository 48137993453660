import React from 'react'
import hero_img from '../../../../assets/img/Services/IotEdgeSolution/predictiveMaintainance.jpg'
export default function HeroSection() { 
  return ( 
    <div className="relative flex flex-col py-16 lg:pt-0 lg:flex-col lg:pb-0">
    <div className="inset-y-0 top-0 right-0 z-0 xl:max-w-[650px] lg:max-w-[520px] max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12  lg:absolute xl:px-0">
      <svg
        className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
        viewBox="0 0 100 100"
        fill="currentColor"
        preserveAspectRatio="none slice"
      >
        <path d="M50 0H100L50 100H0L50 0Z" />
      </svg>
      <img
        className="object-cover w-full bg-fixed h-56 rounded  shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
        src={hero_img}
        alt="hero"
      />
    </div>
    <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
      <div className="lg:mb-16 pt-2 lg:my-20 lg:max-w-xl lg:pr-5">
        <p className="p-2 inline-block px-5 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-[#232f7f]"></p>
        <h2 className="mb-5 text-3xl font-bold tracking-wide text-gray-900 lg:text-5xl sm:leading-none">
        Predictive Maintenance Services
        </h2>
        <p className="pr-5 mb-5 text-base text-gray-700 md:text-lg">
        AeroAegis’s <strong>Predictive Maintenance Services</strong> combine IoT sensors and AI analytics to monitor assets in real time, detect anomalies, and predict equipment failures before they happen. This enables businesses to shift from reactive to proactive maintenance, reducing downtime and optimizing asset performance.
        </p>
      </div>
    </div>
  </div>
  )
}
