import React, { useState, useEffect } from 'react';
import {
  FaHospital,
  FaPills,
  FaFileInvoiceDollar,
  FaMedkit,
} from 'react-icons/fa';

import img1 from '../../../assets/img/Products/AeroPerformai/performance.jpg';
import img2 from '../../../assets/img/Products/AeroPerformai/uptime.jpg';
import img3 from '../../../assets/img/Products/AeroPerformai/feature7.jpg';
import img4 from '../../../assets/img/Products/AeroPerformai/resourcem.jpg';

const BenifitsCard = ({ title, description, icon, imageUrl }) => (
  <div className="bg-white rounded-2xl shadow-xl w-full overflow-hidden transition-all duration-300 hover:shadow-2xl hover:scale-105 mx-auto ">
    <div className="h-48 overflow-hidden">
      <img src={imageUrl} alt={title} className="w-full h-full object-cover" />
    </div>
    <div className="p-6">
      <div className="flex items-center mb-4">
        {/* <div className="text-3xl text-blue-600 mr-3">{icon}</div> */}
        <h3 className="text-xl font-bold text-gray-800">{title}</h3>
      </div>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

const Benifits = () => {
  const benifitsAreas = [
    {
      title: 'Optimized Performance',
      description:
        'With AeroPulse AI, your cloud infrastructure operates at its best, supporting optimal application performance and user experience.',
      icon: <FaHospital />,
      imageUrl: img1,
    },
    {
      title: 'Reduced Downtime',
      description:
        'Our predictive capabilities mean potential issues can be addressed before they cause significant disruptions, leading to reduced downtime and greater reliability.',
      icon: <FaPills />,
      imageUrl: img2,
    },
    {
      title: 'Enhanced Decision Making',
      description:
        'The detailed analytics and forecasts provided by AeroPulse AI empower your team to make informed decisions regarding infrastructure planning, scalability, and investment.',
      icon: <FaMedkit />,
      imageUrl: img3,
    },
    {
      title: 'Efficient Resource Management',
      description:
        "AeroPulse AI's insights into system performance allow for smarter resource allocation, ensuring your infrastructure can meet demand without unnecessary expenditure.",
      icon: <FaFileInvoiceDollar />,
      imageUrl: img4,
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-green-50 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-4">
          How AeroPulse AI Benefits Your Business
        </h1>
        <p className="text-xl text-center text-gray-600 mb-12">
          AeroPulse AI empowers cloud infrastructure with optimized performance,
          predictive insights to minimize downtime, and smarter resource
          management for cost efficiency. Its detailed analytics enable informed
          decision-making, ensuring reliability, scalability, and enhanced user
          experiences.
        </p>
        <div className="grid grid-cols-1 w-full md:grid-cols-2 lg:grid-cols-2 gap-14 ">
          {benifitsAreas.map((area, index) => (
            <BenifitsCard key={index} {...area} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Benifits;
