import React from "react";
import { 
  MdOutlineAutoGraph, 
  MdOutlineSecurity, 
  MdOutlineVerified 
} from "react-icons/md";
import img1 from "../../../../assets/img/Services/IAMSecuritySolutions/Aidriven.jpg"; 

export default function MainSection8() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col md:grid-cols-2 gap-8 items-center">
        {/* Content Section */}
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              AI-Driven Cloud IAM Enhancements
            </h1>
            <p className="text-lg text-gray-600">
              AeroAegis integrates AI into Cloud IAM solutions for enhanced security 
              and operational efficiency.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 - Behavioral Analytics */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineAutoGraph className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Behavioral Analytics
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Detect and respond to anomalies in user behavior, such as unusual 
                login locations or access patterns.
              </p>
            </div>

            {/* Feature 2 - Adaptive Security */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineSecurity className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Adaptive Security
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Adjust access controls in real-time based on AI-driven risk assessments.
              </p>
            </div>

            {/* Feature 3 - Identity Intelligence */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineVerified className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Identity Intelligence
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Optimize identity roles and policies by analyzing user activity 
                and access trends.
              </p>
            </div>
          </div>
        </div>

        {/* Image Section */}
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Managed IAM & Security"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}
