import React from "react";
import { MdAdminPanelSettings, MdOutlineSecurity, MdOutlineGppGood } from "react-icons/md";
import { RiUserSharedLine } from "react-icons/ri";

export default function MainSection1() {
  return (
    <div>
      <div className="mt-6 px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-14 lg:px-8 lg:py-5">
        <div className="flex flex-col lg:flex-row justify-between lg:text-left my-2">
          <h2 className="font-sans text-2xl font-bold tracking-tight text-gray-900 md:text-3xl sm:text-2xl sm:leading-none mb-5 group">
            <span className="inline-block mb-1 sm:mb-4">Key Benefits</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
          <p className="text-gray-700 font-semibold lg:text-xl lg:max-w-md italic">
            "Simplifying identity management and securing cloud access with robust IAM solutions."
          </p>
        </div>

        <section className="py-4 bg-base-100">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 place-items-center">
              {[
                {
                  icon: <MdAdminPanelSettings />,
                  title: "Streamlined Access Management",
                  description: "Enable seamless and unified access across on-premises, cloud, and hybrid systems."
                },
                {
                  icon: <MdOutlineSecurity />,
                  title: "Enhanced Security",
                  description: "Implement strong authentication and access control to safeguard sensitive cloud resources."
                },
                {
                  icon: <MdOutlineGppGood />,
                  title: "Ensured Compliance",
                  description: "Automate compliance checks and maintain detailed audit trails to meet regulatory standards."
                },
                // {
                //   icon: <RiUserSharedLine />,
                //   title: "User Lifecycle Automation",
                //   description: "Automate provisioning and de-provisioning processes for efficient identity lifecycle management."
                // },
              ].map((item, index) => (
                <div
                  key={index}
                  className={`${ index === 2 ? 'md:col-span-2 md:w-full' : '' } card border  h-full lg:w-[300px] lg:col-span-1 border-primary shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2`}
                >
                  <div className="card-body flex flex-col justify-between h-full items-center text-center">
                    <div className="text-4xl text-primary mb-4">{item.icon}</div>
                    <h3 className="card-title text-xl mb-2 min-h-[60px]">{item.title}</h3>
                    <p className="min-h-[70px]">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
