import React from "react";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";
import { IoLockClosedOutline } from "react-icons/io5";
import { BiLike } from "react-icons/bi";
import img1 from "../../../../assets/img/Services/ProductEngineering/iotSec.jpg";
import { FaBullseye, FaChartLine, FaMap, FaRobot, FaRocket, FaSync, FaUserAlt } from "react-icons/fa";
import { MdOutlineVerified, MdUpdate } from "react-icons/md";

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Role-Based Access Control (RBAC)
            </h1>
            <p className="text-lg text-gray-600">
              Ensure that users only have access to the resources they need, reducing the risk of data breaches.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaUserAlt className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Role-Based Permissions</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Assign access permissions based on roles and job functions.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaSync className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Automated Provisioning</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Automate access provisioning to streamline user onboarding and role changes.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineVerified className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Audit and Compliance</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Simplify audit and compliance with detailed access policies and reporting.
              </p>
            </div>
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Role-Based Access Control (RBAC)"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}
