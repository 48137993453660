import React from "react";
import { GiBullseye } from "react-icons/gi";
import { LuBrain, LuThumbsUp } from "react-icons/lu";
import { MdOpenInFull } from "react-icons/md";

export default function MainSection8() {
  const whyChooseData = [
    {
      icon: <GiBullseye />,
      title: "Tailored Solutions",
      description:
        "We create IAM frameworks customized for your organization’s unique challenges and goals.",
    },
    {
      icon: <LuThumbsUp />,
      title: "Expertise Across Leading Platforms",
      description:
        "Our team has deep experience with ForgeRock, Okta, Ping Identity, and Azure AD, ensuring best-in-class implementations.",
    },
    {
      icon: <LuBrain />,
      title: "AI-Powered Enhancements",
      description:
        "We go beyond traditional IAM with AI-driven insights and adaptive security measures.",
    },
    {
      icon: <MdOpenInFull />,
      title: "End-to-End Services",
      description:
        "From strategy and implementation to management and optimization, we provide complete IAM solutions.",
    },
  ];
  return (
    <div className="px-4 py-4 my-auto md:px-12 md:py-12 bg-[#F7F7FC]">
      <div className="flex flex-col mb-6 pb-12 justify-center lg:justify-center lg:flex-row md:mb-8">
        <h2 className="max-w-lg font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block mb-1 sm:mb-4">Why Choose AeroAegis for IAM</span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
        </h2>
      </div>
      <div className="flex flex-wrap justify-center gap-10">
        {whyChooseData.map((item, index) => (
          <div
            key={index}
            className="card w-[400px] border border-primary shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2"
          >
            <div className="card-body items-center text-center">
              <div className="text-4xl text-primary mb-4">{item.icon}</div>
              <h3 className="card-title text-xl mb-2">{item.title}</h3>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
