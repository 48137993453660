import React from "react";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";
import { IoLockClosedOutline } from "react-icons/io5";
import { BiLike } from "react-icons/bi";
import img1 from "../../../../assets/img/Services/ProductEngineering/iotSec.jpg";
import { FaLock, FaTags, FaHandshake } from "react-icons/fa";

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Data Protection and Privacy Compliance
            </h1>
            <p className="text-lg text-gray-600">
              Protect sensitive data and ensure adherence to global data privacy laws.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaLock className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Data Encryption</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Implement end-to-end encryption for data in transit and at rest.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaTags className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Data Classification and Tagging</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Identify and classify sensitive data to enforce access controls.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaHandshake className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Consent Management</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Use blockchain-based solutions to manage user consent for data collection and processing.
              </p>
            </div>
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Data Protection and Privacy Compliance"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}
