import React from 'react'
import hero_img from '../../../assets/img/Industries/TelecomandNetworks/final_network.png'
import { Link } from 'react-router-dom'
export default function FinalSection() {
  return (
    <section
    className="relative text-white py-20 md:py-10 bg-cover bg-center"
    style={{
      backgroundImage: `url('${hero_img}')`,
    }}
  > 
    {/* Overlay */}
    <div className="absolute inset-0 bg-black bg-opacity-50"></div>
    
    {/* Content */}
    <div className="container mx-auto px-4 text-center relative z-10">
      <h1 className="text-4xl md:text-6xl font-bold mb-4">
        Partner with AeroAegis
      </h1>
      <p className="text-xl mb-4">
      At Aero Aegis, we help telecom and networking businesses embrace the future with scalable, secure, and innovative solutions. Whether you’re deploying 5G, optimizing network performance, or enabling IoT services, we have the expertise to drive your success in a connected world.
      </p>
      <p className='mb-4'>Let’s build the future of connectivity together.</p>
      <Link to={'/contact-us'} className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
        Contact Us
      </Link>
    </div>
  </section>
  )
}
