import React from "react";
import {
  MdPhonelinkLock,
  MdSecurity,
  MdOutlineVisibility,
  MdOutlinePolicy,
} from "react-icons/md";

import { GoGraph } from "react-icons/go";
import { LuBrain } from "react-icons/lu";
import img1 from "../../../../assets/img/Services/IAMSecuritySolutions/cyberAttack.webp"; // Ensure correct image path

export default function MainSection3() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col md:grid-cols-2 gap-8 items-center">
        {/* Image Section */}

        {/* Content Section */}
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Continuous Monitoring and Threat Detection
            </h1>
            {/* <p className="text-lg text-gray-600">
              AeroAegis's Zero Trust Security solutions eliminate implicit
              trust, enforcing continuous validation, access control, and
              real-time monitoring to safeguard critical IT assets and prevent
              cyber threats.
            </p> */}
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 - Continuous Authentication */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <GoGraph className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Real-Time Behavior Analytics
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Use AI to detect unusual patterns in user and device behavior
                that could indicate a potential breach.
              </p>
            </div>

            {/* Feature 2 - Least Privilege Access */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdSecurity className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Endpoint Security Integration
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Continuously monitor all endpoints (e.g., laptops, mobile
                devices, IoT devices) to ensure they remain secure and
                compliant.
              </p>
            </div>

            {/* Feature 3 - Continuous Visibility & Monitoring */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <LuBrain className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Incident Response Automation
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Automate responses to detected threats, such as revoking access,
                alerting admins, or isolating compromised devices.
              </p>
            </div>

            {/* Feature 4 - Policy-Based Security Controls */}
            {/* <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlinePolicy className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Policy-Based Access Controls
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Implement dynamic access policies that adapt based on user
                roles, device health, location, and risk levels.
              </p>
            </div> */}
          </div>
        </div>
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Zero Trust Architecture"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}