import React from "react";
import { MdOutlineLightbulb } from "react-icons/md"; // AI/ML Solutions
import { MdOutlineCloudQueue } from "react-icons/md"; // Cloud & Edge Scaling
import { MdOutlineSpeed } from "react-icons/md"; // Optimization
import { MdOutlineBuild } from "react-icons/md"; // Automation
import { FaLightbulb, FaPencilRuler, FaRocket, FaCogs } from "react-icons/fa";
import { GoGraph } from "react-icons/go";

import { LiaHandsHelpingSolid } from "react-icons/lia";
import { IoMdTrendingUp } from "react-icons/io";
import { LuBrainCircuit } from "react-icons/lu";

export default function MainSection1() {
  return (
    <div>
      <div className="lg:mt-6 px-4 py-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col justify-center mb-6 lg:justify-center lg:flex-row md:mb-8">
          <h2 className="max-w-4xl mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">
              Why Choose AeroAegis for IP Development?
            </span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
        </div>

        <section className="py-4 bg-base-100">
          <div className="container mx-auto px-2">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 place-items-center">
              {[
                {
                  icon: <LuBrainCircuit />,
                  title: "Proven Expertise",
                  description:
                    "With a portfolio of successful IPs like Maturity Spectrum 360 and SmartChaos Lab, AeroAegis brings unmatched experience in developing transformative technologies.",
                },
                {
                  icon: <IoMdTrendingUp />,
                  title: "Cutting-Edge Focus",
                  description:
                    "Our relentless investment in emerging fields like AI, Quantum Computing, and Web3 ensures that our IPs are always ahead of the curve.",
                },
                {
                  icon: <LiaHandsHelpingSolid />,
                  title: "Collaborative Innovation",
                  description:
                    "We work closely with businesses to understand their needs and co-create IPs that deliver measurable outcomes.",
                },
              ].map((item, index) => (
                <div
                  key={index}
                  className={`${ index === 2 ? 'md:col-span-2 md:w-full' : '' } card border  h-full lg:w-[300px] lg:col-span-1 border-primary shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2`}
                >
                  <div className="card-body items-center text-center">
                    <div className="text-4xl text-primary mb-4">
                      {item.icon}
                    </div>
                    <h3 className="card-title text-xl mb-2">{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
