import React from "react";
import { FaRegClipboard, FaShieldAlt, FaBell } from "react-icons/fa";
import img1 from "../../../../assets/img/Services/IAMSecuritySolutions/compliance.jpg";

export default function MainSection3() {
  return (
    <div className="container mx-auto px-4 py-12 md:px-10">
      {/* On mobile, use flex with reversed column order.
          On medium screens and up, switch to a grid layout with 2 columns. */}
      <div className="flex flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
        <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block mb-1 sm:mb-4">
            Our Offerings
          </span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
        </h2>
      </div>
      <div className="flex flex-col-reverse md:grid md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Automated Compliance Management"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Automated Compliance Management
            </h1>
            <p className="text-lg text-gray-600">
              Eliminate manual processes and ensure continuous compliance with automated workflows.
            </p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaRegClipboard className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Policy Management</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Define, enforce, and update policies to align with changing regulations.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaShieldAlt className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Automated Monitoring</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Continuously monitor systems, applications, and networks for compliance violations.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaBell className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Real-Time Alerts</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Receive instant notifications of non-compliance incidents, enabling prompt action.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
