import React from "react";
import { MdManageAccounts, MdOutlineAutoGraph, MdOutlineSecurity, MdOutlineVerified } from "react-icons/md";
import img1 from "../../../../assets/img/Services/IAMSecuritySolutions/ManagedIAMServices.jpg"; // Update with actual image

export default function MainSection5() {
  return ( 
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        
        {/* Image Section */}
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Managed IAM & Security"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>

        {/* Content Section */}
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Role-Based Access Control (RBAC) and Attribute-Based Access Control (ABAC)
            </h1>
            <p className="text-lg text-gray-600">
              Ensure that users only have access to the cloud resources necessary for their roles.</p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 - 24/7 IAM Monitoring */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdManageAccounts className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Granular Job-Based Permissions</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Assign granular permissions based on job functions, ensuring a least privilege approach to access control.
              </p>
            </div>

            {/* Feature 2 - AI-Powered Threat Detection */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineSecurity className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Dynamic ABAC Access Control</h3>
              </div>
              <p className="text-gray-600 text-sm">
              Implement ABAC policies to dynamically grant access based on user attributes such as location, department, or device type.
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
