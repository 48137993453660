import React from "react";
import { MdLightbulb, MdOutlineSpeed, MdOutlineBuild } from "react-icons/md";
import { FaHandsHelping, FaCarCrash, FaRoad } from "react-icons/fa";


export default function MainSection1() {
  return (
    <div>
      <div className="mt-6 px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col justify-center mb-6 lg:justify-center lg:flex-row md:mb-8">
          <h2 className="max-w-4xl mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">Why Choose AeroAegis for Automotive Solutions?</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
          
        </div>

      <section className="py-4 bg-base-100">
        <div className="container mx-auto px-2">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-8 lg:gap-8">
            {[
  {
    icon: <FaHandsHelping />,
    title: "Technology Leadership",
    description:
      "With expertise in IoT, AI, and blockchain, we deliver cutting-edge solutions tailored for the automotive industry’s unique challenges.",
  },
  {
    icon: <MdLightbulb />,
    title: "End-to-End Innovation",
    description:
      "From manufacturing transparency to real-time vehicle monitoring, our solutions address every stage of the automotive lifecycle.",
  },
  {
    icon: <FaCarCrash />,
    title: "Enhanced Safety and Compliance",
    description:
      "Our AI-driven systems ensure safer roads and vehicles while meeting global safety standards and regulatory requirements.",
  },
  {
    icon: <FaRoad />,
    title: "Future-Ready Capabilities",
    description:
      "With a focus on autonomous driving, connected ecosystems, and sustainability, AeroAegis helps automotive businesses lead in a rapidly changing market.",
  },
].map((item, index) => (
              <div key={index} className="card border w-full border-primary shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2">
                <div className="card-body items-center text-center">
                  <div className="text-4xl text-primary mb-4">{item.icon}</div>
                  <h3 className="card-title text-xl mb-2">{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      </div>
    </div>
  );
}
