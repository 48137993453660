import React from "react";
import { MdAttachMoney, MdOutlineThumbDown, MdOutlineSettings, MdOutlineAssignmentTurnedIn, MdHandshake } from "react-icons/md";
import { RiShieldCheckFill, RiGlobalLine } from "react-icons/ri";
import { GiArchiveResearch } from "react-icons/gi";

export default function MainSection1() {
  return (
    <div>
      <div className="mt-6 md:px-16 px-4 py-4 mx-auto xl:px-24 lg:py-20">
        <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
          <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">Why Compliance and Audit Readiness Matter</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
          <p className="text-gray-700 font-semibold lg:text-2xl lg:max-w-md italic">
            "Failure to meet regulatory requirements can lead to severe consequences, including"
          </p>
        </div>

        <section className="py-4 bg-base-100">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap gap-8 justify-center items-stretch">
              {[
                {
                  icon: <MdAttachMoney className="w-full h-full" />,
                  title: "Financial Penalties",
                  description: "Non-compliance can result in hefty fines and legal fees.",
                },
                {
                  icon: <MdOutlineThumbDown className="w-full h-full" />,
                  title: "Reputational Damage",
                  description: "Data breaches or audit failures can erode customer and partner trust.",
                },
                {
                  icon: <MdOutlineSettings className="w-full h-full" />,
                  title: "Operational Disruptions",
                  description: "Manual compliance processes drain resources and hinder productivity.",
                },
              ].map((item, index) => (
                <div
                  key={index}
                  className= "card border max-w-[300px] w-full lg:col-span-1 border-primary shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2"
                >
                  <div className="card-body items-center text-center">
                    <div className="text-4xl text-primary mb-4">{item.icon}</div>
                    <h3 className="card-title text-xl mb-2">{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* <div className="mt-16">
          <h3 className="text-2xl font-bold text-gray-900 mb-6">Benefits of Robust Compliance Strategy</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                icon: <RiShieldCheckFill className="w-full h-full" />,
                title: "Global Standards Adherence",
                description: "Continuous compliance with GDPR, HIPAA, PCI DSS through automated monitoring.",
              },
              {
                icon: <GiArchiveResearch className="w-full h-full" />,
                title: "Audit Readiness",
                description: "Maintain immutable audit trails and real-time compliance dashboards.",
              },
              {
                icon: <RiGlobalLine className="w-full h-full" />,
                title: "Cross-Industry Solutions",
                description: "Tailored frameworks for finance, healthcare, energy, and aerospace sectors.",
              },
            ].map((item, index) => (
              <div
                key={index}
                className="card border border-primary shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2"
              >
                <div className="card-body items-center text-center">
                  <div className="text-4xl text-primary mb-4">{item.icon}</div>
                  <h3 className="card-title text-xl mb-2">{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div> */}
      </div>
    </div>
  );
}