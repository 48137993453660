import React from 'react'
import customization_img from "../../../assets/img/iiot/features/104783.jpg"
import industry_img from "../../../assets/img/iiot/features/2148783064.jpg"
import benchmarking_img from "../../../assets/img/iiot/features/pexels-pixabay-60504.jpg"
import historical_img from "../../../assets/img/iiot/features/pexels-hbharun-3701276.jpg"

const BenefitCard = ({ title, description, imageUrl }) => (
	<div className="bg-white rounded-2xl shadow-xl w-full overflow-hidden transition-all duration-300 hover:shadow-2xl hover:scale-105 mx-auto">
	  <div className="h-48 overflow-hidden">
		<img src={imageUrl} alt={title} className="w-full h-full object-cover" />
	  </div>
	  <div className="p-6">
		<div className="flex items-center mb-4">
		  {/* <div className="text-3xl text-blue-600 mr-3">{icon}</div> */}
		  <h3 className="text-xl font-bold text-gray-800">{title}</h3>
		</div>
		<p className="text-gray-600">{description}</p>
	  </div>
	</div>
  );
  
  const ProductBenefits = () => {
  
	const benefits = [
	  {
		title: "Operational Efficiency",
		description: "By leveraging real-time data analytics and AI-driven insights, the platform optimizes operational workflows, reduces manual intervention, and enhances overall efficiency.",
	
		imageUrl: customization_img
	  },
	  {
		title: "Cost Reduction",
		description: "Through predictive maintenance, anomaly detection, and energy efficiency monitoring, the platform significantly lowers operational costs and prevents costly downtimes.",
	
		imageUrl: industry_img
	  },
	  {
		title: "Enhanced Security",
		description: "With robust security features, the platform ensures that all industrial data is secure, protecting against potential cyber threats and data breaches.",

		imageUrl: benchmarking_img
	  },
	  {
		title: "Improved Decision Making",
		description: "The platform's advanced analytics capabilities provide actionable insights, enabling informed decision making and strategic planning.",
		imageUrl: historical_img
	  }
	];
  
  
  
  
	return (
	  <div className="min-h-screen bg-gradient-to-br from-blue-50 to-green-50 py-16 px-4 sm:px-6 lg:px-8">
		<div className="max-w-7xl mx-auto">
		  <h1 className="text-4xl font-bold text-center text-gray-800 mb-4">
			 Key Benefits
		  </h1>
		  <p className="text-xl text-center text-gray-600 mb-12">
		  Leveraging operational efficiency, reduce downtime, and revolutionize industries.
		  </p>
		  <div className="grid grid-cols-1 w-full md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-14">
			{benefits.map((area, index) => (
			  <BenefitCard key={index} {...area}/>
			))}
		  </div>
		</div>
	  </div>
	);
  };
  
  export default ProductBenefits;
