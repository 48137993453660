import React from 'react';


import hero_img from '../../../../assets/img/Services/systemIntegration/2149406772.jpg';
import Img1 from '../../../../assets/img/Services/systemIntegration/two/2149399285.jpg';
import Img2 from '../../../../assets/img/Services/systemIntegration/two/108505.jpg';
import Img3 from '../../../../assets/img/Services/systemIntegration/two/167050.jpg';
import Img4 from '../../../../assets/img/Services/systemIntegration/two/22379.jpg';
import {
  IoCheckmarkCircleOutline,
  IoLockClosedOutline,
} from 'react-icons/io5';
import { BiCoinStack, BiLike } from 'react-icons/bi';
import { FaCogs, FaLightbulb, FaPencilRuler, FaRocket } from 'react-icons/fa';
import HeroSection from '../components/HeroSection';
import FeatureSection from '../components/FeatureSection';
import Section from '../components/Section';
import AnimationRevealPage from '../../../../helpers/AnimationRevealPage';


const index = () => {

  const HeroData = {
    title: "Cloud Migration and Integration",
    description: "Modern businesses require agile, scalable, and cost-effective IT environments. AeroAegis provides<strong> Cloud Migration and Integration </strong>services to seamlessly transition your on-premises applications and data to the cloud, ensuring security, scalability, and performance.",
    image: hero_img // Replace with your actual image path or URL
  };
  

  // Features data for "Key Benefits"
  const features = [
    {
      icon: <FaRocket />,
      title: "Faster, Secure Migration",
      description: "Quickly and securely migrate to multi-cloud or hybrid environments.",
    },
    {
      icon: <FaCogs />,
      title: "Improved Scalability",
      description: "Enhanced scalability and performance to meet evolving business needs.",
    },
    {
      icon: <FaLightbulb />,
      title: "Integrated Environments",
      description: "Seamlessly connect data and processes for unified cloud workflows.",
    },
    {
      icon: <BiCoinStack />,
      title: "Future-Ready Infrastructure",
      description: "Support innovation and growth with a modernized IT environment.",
    },
  ];
  

  // Section data
  const sectionData = [
    {
      imageSrc: Img1, // Replace with actual image
      title: "Multi-Cloud Strategy",
      description: "Expertise in cloud platforms, including Microsoft Azure, AWS, Google Cloud Platform (GCP), and Alibaba Cloud (Official Partner).",
      features: [
        {
          icon: IoCheckmarkCircleOutline,
          title: "Cloud Expertise",
          description: "Certified expertise in leading cloud platforms.",
        },
        {
          icon: BiCoinStack,
          title: "Cost Optimization",
          description: "Design cost-efficient multi-cloud architectures.",
        },
        {
          icon: IoLockClosedOutline,
          title: "Enhanced Security",
          description: "Implement secure, compliant cloud environments.",
        },
        {
          icon: BiLike,
          title: "Tailored Solutions",
          description: "Custom strategies based on your business goals.",
        },
      ],
    },
    {
      imageSrc: Img2, // Replace with actual image
      title: "Seamless Migration",
      description: "Transition workloads, applications, and databases to public, private, or hybrid cloud environments without disruptions.",
      features: [
        {
          icon: IoCheckmarkCircleOutline,
          title: "Zero Downtime",
          description: "Minimize business impact during migrations.",
        },
        {
          icon: BiCoinStack,
          title: "Database Migration",
          description: "Effortlessly migrate large databases to the cloud.",
        },
        {
          icon: IoLockClosedOutline,
          title: "Data Protection",
          description: "Ensure data integrity during the migration process.",
        },
        {
          icon: BiLike,
          title: "Custom Workflows",
          description: "Adapt migrations to unique business processes.",
        },
      ],
    },
    {
      imageSrc: Img3, // Replace with actual image
      title: "Cloud Integration",
      description: "Connect on-premises systems with cloud-based services and applications for seamless data flow and unified workflows.",
      features: [
        {
          icon: IoCheckmarkCircleOutline,
          title: "Unified Ecosystem",
          description: "Integrate diverse systems into one cohesive framework.",
        },
        {
          icon: BiCoinStack,
          title: "Enhanced Collaboration",
          description: "Enable real-time collaboration across teams.",
        },
        {
          icon: IoLockClosedOutline,
          title: "Secure Connectivity",
          description: "Safeguard data during integration.",
        },
        {
          icon: BiLike,
          title: "Improved Efficiency",
          description: "Streamline workflows for better productivity.",
        },
      ],
    },
    {
      imageSrc: Img4, // Replace with actual image
      title: "Performance Optimization",
      description: "Ensure cloud applications are optimized for cost, speed, and scalability post-migration.",
      features: [
        {
          icon: IoCheckmarkCircleOutline,
          title: "Speed Enhancement",
          description: "Achieve faster application response times.",
        },
        {
          icon: BiCoinStack,
          title: "Cost Efficiency",
          description: "Lower operational costs with optimized resources.",
        },
        {
          icon: IoLockClosedOutline,
          title: "Reliable Operations",
          description: "Maintain application reliability under heavy loads.",
        },
        {
          icon: BiLike,
          title: "Future Scalability",
          description: "Prepare for growth with scalable architecture.",
        },
      ],
    },
  ];
  

  return (
    <>
      <HeroSection
        title={ HeroData.title}
        description={HeroData.description}
        image={hero_img}
      />

      <AnimationRevealPage>
        {/* Key Benefits Section */}
        <FeatureSection
          mainTitle="Key Benefits"
          description="Migrate on-premises applications to public, private, or hybrid cloud platforms."
          features={features}
        />

        {/* Our Offerings Heading */}
        <div className="flex flex-col px-2 mb-4 justify-center lg:justify-center lg:flex-row md:mb-4">
          <h2 className="md:px-10 max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
            <span className="inline-block mb-1 sm:mb-4">Our Offerings</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
          </h2>
        </div>

        {/* Offerings Sections */}
        {sectionData.map((section, index) => (
          <Section
            key={index}
            imageSrc={section.imageSrc}
            title={section.title}
            description={section.description}
            features={section.features}
            isImageLeft={index % 2 !== 0} // Alternate image position
          />
        ))}
      </AnimationRevealPage>

      
    </>
  );
};

export default index;
