import React from "react";
import { MdOutlineVerifiedUser, MdSecurity, MdBlock } from "react-icons/md";
import { RiShieldCheckFill } from "react-icons/ri";

export default function MainSection1() {
  return (
    <div>
      <div className="mt-6 px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">

        <div className="mt-16">
          <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
            <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
              <span className="inline-block mb-1 sm:mb-4">Key Benefits</span>
              <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
            </h2>
            <p className="text-gray-700 font-semibold lg:text-xl lg:max-w-md italic">
              "Empowering Organizations to Mitigate Modern Cyber Threats with Zero Trust."
            </p>

          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 place-items-center">
            {[
              {
                icon: <RiShieldCheckFill />,
                title: "Continuous Validation",
                description:
                  "Access is continuously verified throughout a session, using real-time analytics and monitoring.",
              },
              {
                icon: <MdSecurity />,
                title: "Least Privilege Access",
                description:
                  "Users and devices are granted access only to the resources they need, and nothing more.",
              },
              {
                icon: <MdBlock />,
                title: "Micro-Segmentation",
                description:
                  "Systems and data are segmented into smaller zones to limit the potential impact of breaches.",
              },
            ].map((item, index) => (
              <div
                key={index}
                className={`${ index === 2 ? 'md:col-span-2 md:w-full' : '' } card border  h-full lg:w-[300px] lg:col-span-1 border-primary shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2`}
              >
                <div className="card-body items-center text-center">
                  <div className="text-4xl text-primary mb-4">{item.icon}</div>
                  <h3 className="card-title text-xl mb-2">{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

      </div>
    </div>
  );
}
