import React from "react";
import { LuEye, LuBrain, LuThumbsUp, LuShield } from "react-icons/lu";

export default function MainSection8() {
  const whyChooseData = [
    {
      icon: <LuShield />,
      title: "End-to-End Expertise",
      description:
        "From strategy to deployment, AeroAegis ensures a seamless Zero Trust implementation tailored to your needs.",
    },
    {
      icon: <LuBrain />,
      title: "AI-Driven Security Enhancements",
      description:
        "Leverage AI-powered insights to detect threats, optimize access policies, and prevent breaches in real time.",
    },
    {
      icon: <LuThumbsUp />,
      title: "Industry-Leading Partnerships",
      description:
        "We collaborate with top technology providers like Okta, Azure AD, and Palo Alto Networks to deliver proven, reliable solutions.",
    },
    {
      icon: <LuEye />,
      title: "Future-Ready Framework",
      description:
        "AeroAegis designs scalable and adaptive Zero Trust solutions to meet your organization’s evolving security needs.",
    },
  ];

  return (
    <div className="px-4 py-4 my-auto md:px-12 md:py-12 bg-[#F7F7FC]">
      <div className="flex flex-col mb-6 pb-12 justify-center lg:justify-center lg:flex-row md:mb-8">
        <h2 className="max-w-lg font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block mb-1 sm:mb-4">Why Choose AeroAegis for Zero Trust?</span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
        </h2>
      </div>
      <div className="flex flex-wrap justify-center gap-10">
        {whyChooseData.map((item, index) => (
          <div
            key={index}
            className="card w-[400px] border border-primary shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2"
          >
            <div className="card-body items-center text-center">
              <div className="text-4xl text-primary mb-4">{item.icon}</div>
              <h3 className="card-title text-xl mb-2">{item.title}</h3>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
