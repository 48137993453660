import img3 from "../../../../assets/img/Products/AeroGenix/deploy.svg";
import img1 from "../../../../assets/img/Products/AeroGenix/Data points-rafiki.svg";
import img4 from "../../../../assets/img/Products/AeroGenix/monitoring.svg";
import img2 from "../../../../assets/img/Products/AeroGenix/people creating robot-rafiki.svg";

export default function  CloudIAMImplementationServices() {
  const steps = [
    {
      number: "1",
      title: "Strategy and Assessment",
      description: [
        "Analyze your current identity landscape and cloud strategy to identify gaps and recommend solutions.",
        "Develop a tailored Cloud IAM roadmap to meet your security, scalability, and compliance goals.",
      ],
      image: img1,
    },
    {
      number: "2",
      title: "Cloud IAM Deployment",
      description: [
        "Implement leading IAM platforms like ForgeRock, Okta, and Azure AD to secure cloud access.",
        "Ensure seamless integration with your existing IT ecosystem and third-party cloud applications.",
      ],

      image: img2,
    },
    {
      number: "3",
      title: "Identity Lifecycle Management",
      description: [
        "Automate provisioning, de-provisioning, and role assignments across multi-cloud environments.",
        "Maintain real-time visibility into user activity and access levels.",
      ],

      image: img3,
    },
    {
      number: "4",
      title: "Compliance and Governance",
      description: [
        "Automate compliance checks and reporting to meet global standards like GDPR, HIPAA, and PCI DSS.",
        "Provide detailed audit trails for identity and access activity, ensuring transparency and accountability.",
      ],

      image: img4,
    },
    {
      number: "4",
      title: "Managed Cloud IAM Services",
      description: [
        "Monitor, maintain, and optimize your Cloud IAM implementation with AeroAegis’s managed services.",
        "Proactively address emerging threats and adapt to evolving business needs.",
      ],

      image: img4,
    },
  ];

  return (
    <div className="px-4 py-16 sm:px-6 lg:px-8 bg-white">
     <div className="flex flex-col mb-6 pb-12 justify-center lg:justify-center lg:flex-row md:mb-8">
      <h2 className="max-w-lg font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
        <span className="inline-block mb-1 sm:mb-4">Our Cloud IAM Implementation Services</span>
        <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
      </h2>
    </div>

      <div className="flex flex-wrap justify-center gap-8 bg-white">
        {steps.map((step) => (
          <div
            key={step.number}
            className="bg-white max-w-[450px] xl:max-w-[500px] rounded-lg p-8  shadow-xl hover:-translate-y-1 hover:shadow-2xl  transition-all duration-300 border border-primary"
          >
            <div className="flex flex-col justify-between items-start gap-6">
              <div className="space-y-4">
                <div className="flex-shrink-0">
                  <img
                    src={step.image}
                    alt={`Step ${step.number} - ${step.title}`}
                    className="w-32 h-32 mx-auto object-contain"
                  />
                </div>
                <h3 className="text-xl font-semibold text-gray-900">
                  {step.title}
                </h3>
                <ul className="text-gray-500 list-disc	pl-5 ">
                  <li className="m-2">{step.description[0]}</li>
                  <li className="m-2">{step.description[1]}</li>
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}