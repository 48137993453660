import React, { useState, useEffect } from "react";
import {
  FaHospital,
  FaPills,
  FaFileInvoiceDollar,
  FaMedkit,
} from "react-icons/fa";
import img1 from "../../../assets/img/Industries/Healthcare/monitor.png";
import img2 from "../../../assets/img/Industries/Healthcare/tablets.png";
import img3 from "../../../assets/img/Industries/Healthcare/33.jpg";
import img4 from "../../../assets/img/Industries/Healthcare/iot.jpg";

const HealthcareCard = ({ title, description, icon, imageUrl }) => (
  <div className="bg-white rounded-2xl shadow-xl w-full overflow-hidden transition-all duration-300 hover:shadow-2xl hover:scale-105 mx-auto">
    <div className="h-48 overflow-hidden">
      <img src={imageUrl} alt={title} className="w-full h-full object-cover" />
    </div>
    <div className="p-6">
      <div className="flex items-center mb-4">
        {/* <div className="text-3xl text-blue-600 mr-3">{icon}</div> */}
        <h3 className="text-xl font-bold text-gray-800">{title}</h3>
      </div>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

const EnhancedHealthcareTech = () => {
  const healthcareAreas = [
    {
      title: "Hospitals and Clinics",
      description:
        "Enhance patient experiences and streamline operations through AI, IoT, and cloud technologies.",
      icon: <FaHospital />,
      imageUrl: img1,
    },
    {
      title: "Pharmaceutical Companies",
      description:
        "Accelerate drug discovery, ensure regulatory compliance, and improve manufacturing with advanced analytics and blockchain.",
      icon: <FaPills />,
      imageUrl: img2,
    },
    {
      title: "Payers and Insurers",
      description:
        "Optimize claims management and fraud detection with AI and blockchain solutions.",
      icon: <FaFileInvoiceDollar />,
      imageUrl: img3,
    },
    {
      title: "Medical Device Companies",
      description:
        "Innovate with IoT and AI to develop smart, connected devices that deliver better patient outcomes.",
      icon: <FaMedkit />,
      imageUrl: img4,
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-green-50 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-4">
          Transforming Key Areas of Healthcare
        </h1>
        <p className="text-xl text-center text-gray-600 mb-12">
          Leveraging cutting-edge technology to revolutionize healthcare
          delivery and outcomes
        </p>
        <div className="grid grid-cols-1 w-full md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-14  ">
          {healthcareAreas.map((area, index) => (
            <HealthcareCard key={index} {...area} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EnhancedHealthcareTech;
