import React from 'react';
import AnimationRevealPage from '../../../../helpers/AnimationRevealPage';
import MainSection1 from './MainSection1';
import MainSection2 from './MainSection2'; 
import MainSection3 from './MainSection3';
import MainSection4 from './MainSection4';
import MainSection5 from './MainSection5';
import FinalSection from './FinalSection';
import HeroSection from './HeroSection';
import IndustriesWeServe from './IndustriesWeServe'
import MainSection6 from './MainSection6';
import MainSection7 from './MainSection7';
import MainSection8 from './MainSection8';
import MainSection9 from './MainSection9';
import Tech from './Tech';


export default function CloudIamSolution() {
  return (
    <div>
      <HeroSection />
      <AnimationRevealPage>
        <MainSection1 />
        <MainSection2 /> 
        <MainSection3 />
        <MainSection4 />
        <MainSection5 />
        <MainSection8 />
        <Tech />
        <MainSection9 />
        <IndustriesWeServe />
        <MainSection7 />
        <MainSection6 />
        <FinalSection />
      </AnimationRevealPage>
    </div>
  );
}
