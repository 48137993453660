import React from 'react';
import { MdSecurity, MdCloudDone, MdOutlineGppGood, MdAnalytics, MdManageAccounts, MdOutlineVpnLock } from 'react-icons/md';
import { Link } from 'react-router-dom';
import Tech from './Tech'

const FinalSection = () => {
  return (
    <div className="bg-gray-100">
      <section className="bg-blue-600 text-white py-20">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
            Secure Your Organization with AeroAegis Zero Trust Security
          </h1>
          <p className="text-xl mb-8">
          AeroAegis Zero Trust Security Framework ensures that your organization remains resilient in the face of modern cybersecurity challenges. By implementing strict validation, continuous monitoring, and adaptive policies, we help you safeguard your critical assets and stay ahead of threats. Visit www.aeroaegis.com or connect with our experts for a personalized consultation. Together, we’ll secure your business—one access point at a time.
          </p>
          <Link to={'/contact-us'} className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
            Contact Us
          </Link>
        </div>
      </section>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="flex items-center justify-center mb-4">{icon}</div>
      <h3 className="text-xl font-semibold mb-2 text-center">{title}</h3>
      <p className="text-gray-600 text-center">{description}</p>
    </div>
  );
};

export default FinalSection;