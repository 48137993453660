import React from "react";
import { Link } from "react-router-dom";


const AeroAegisLanding = () => {
  return (
    <div>
      {/* Hero Section */}
      <section className="bg-blue-600 text-white py-20">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
          Achieve Continuous Compliance with AeroAegis
          </h1>
          <p className="text-xl mb-8">
          At AeroAegis, we understand the complexities of regulatory compliance and the importance of audit readiness. Our solutions combine cutting-edge technology, expert guidance, and industry-specific strategies to help you navigate the regulatory landscape with confidence. Visit www.aeroaegis.com or connect with our compliance experts for a personalized consultation. Together, we’ll simplify compliance and enhance trust—one regulation at a time.
          </p>
          <Link
            to="/contact-us"
            className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300"
          >
            Contact Us
          </Link>
        </div>
      </section>
    </div>
  );
};

export default AeroAegisLanding;
