import React from "react";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";
import { IoLockClosedOutline } from "react-icons/io5";
import { BiLike } from "react-icons/bi";
import img1 from "../../../../assets/img/Services/ProductEngineering/iotPlatD.jpg";
import { FaCalendarAlt, FaCompass, FaHeartbeat, FaPaintBrush, FaReact, FaRegChartBar, FaUserAlt } from "react-icons/fa";
import { GoGraph } from "react-icons/go";

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Advanced AI-Driven Enhancements"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Advanced AI-Driven Enhancements
            </h1>
            <p className="text-lg text-gray-600">
              AeroAegis brings AI-powered intelligence to IAM, enabling predictive and adaptive security measures.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaRegChartBar className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Behavioral Analytics</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Detect unusual user behavior to prevent insider threats and breaches.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaCompass className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Adaptive Authentication</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Dynamically adjust access controls based on real-time context, such as location or device.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaReact className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Identity Intelligence</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Leverage AI to optimize roles, permissions, and access policies based on usage patterns.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
