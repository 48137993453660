import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";
import { MdOutlineSchool } from "react-icons/md"; // Advanced Model Training
import { MdOutlineCloudDone } from "react-icons/md";
import img1 from "../../../../assets/img/Services/IAMSecuritySolutions/monitoring2.jpg";
import { RiPoliceBadgeLine } from "react-icons/ri";
import { MdOutlineAccessTime } from "react-icons/md";
import { BsRecordCircle } from "react-icons/bs";

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Access Controls and Monitoring
            </h1>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <RiPoliceBadgeLine className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Granular Access Policies
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Define fine-grained access policies to limit who can access what, when, and how.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineAccessTime className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Just-in-Time (JIT) Access
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Provide temporary privileged access only when required, reducing
                the attack surface and exposure time.
              </p>
            </div>

            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <BsRecordCircle className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Behavioral Monitoring
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Use AI and machine learning to detect anomalies in privileged
                activity, such as unusual access times or suspicious commands.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
