import React from 'react'

import { MdTimeline } from 'react-icons/md';
import { MdBuild } from 'react-icons/md';
import { MdArrowUpward } from 'react-icons/md';
import { MdLock } from 'react-icons/md';
export default function Whytochoose() {

//   
return (
//     <div>
//         <section class="bg-white dark:bg-gray-900">
//     <div class="container px-6 py-5 mx-auto">
//         <h1 class="text-2xl text-center font-semibold text-gray-800 capitalize lg:text-3xl dark:text-white">Why Choose <br/> <span class="underline decoration-blue-500">AeroEdge?</span></h1>



//         <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-2 xl:grid-cols-2">
//             <div class="p-8 bg-slate-100 space-y-3 border-2 border-blue-200 dark:border-blue-300 rounded-xl">

//                 <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">Real Time Analytics</h1>

//                 <p class="text-gray-500 dark:text-gray-300 font-semibold">
// 				Make data-driven decisions faster with our edge-computing capabilities.

//                 </p>
//             </div>

//             <div class="p-8 bg-slate-100 space-y-3 border-2 border-blue-200 dark:border-blue-300 rounded-xl">

//                 <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">Predictive Maintenance</h1>

//                 <p class="text-gray-500 dark:text-gray-300 font-semibold">
// 				Reduce downtime and extend equipment life with advanced AI algorithms.

//                 </p>
//             </div>

//             <div class="p-8 bg-slate-100 space-y-3 border-2 border-blue-200 dark:border-blue-300 rounded-xl">

//                 <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">Scalability</h1>

//                 <p class="text-gray-500 dark:text-gray-300 font-semibold">
// 				Whether you're a small enterprise or a large industrial complex, our platform scales effortlessly to meet your needs.

//                 </p>
//             </div>

//             <div class="p-8 bg-slate-100 space-y-3 border-2 border-blue-200 dark:border-blue-300 rounded-xl">

//                 <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">Comprehensive Security</h1>

//                 <p class="text-gray-500 dark:text-gray-300 font-semibold">
// 				Rest assured that your data is safe and compliant with industry standards.

//                 </p>
//             </div>
 
//         </div>
//     </div>
// </section>
//     </div>
<div>
<div className="mt-1 px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-2">
  <div className="flex flex-col justify-center mb-6 lg:justify-center lg:flex-row md:mb-8">
    <h2 className="max-w-4xl mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
      <span className="inline-block mb-1 sm:mb-4">Why Choose AeroEdge?</span>
      <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
    </h2>
    
  </div>

<section className="py-4 bg-base-100">
  <div className="container mx-auto px-2">
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-8 lg:gap-16 justify-items-center">
      {[
    { icon: <MdTimeline />, title: "Real Time Analytics", description: "Make data-driven decisions faster with our edge-computing capabilities." },
    { icon: <MdBuild />, title: "Predictive Maintenance", description: "Reduce downtime and extend equipment life with advanced AI algorithms." },
    { icon: <MdArrowUpward />, title: "Scalability", description: "Whether you're a small enterprise or a large industrial complex, our platform scales effortlessly to meet your needs." },
    { icon: <MdLock />, title: "Comprehensive Security", description: "Rest assured that your data is safe and compliant with industry standards." },
].map((item, index) => (
        <div key={index} className="card border lg:w-full border-primary shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2">
          <div className="card-body items-center text-center">
            <div className="text-4xl text-primary mb-4">{item.icon}</div>
            <h3 className="card-title text-xl mb-2">{item.title}</h3>
            <p>{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
</section>

</div>
</div>
  )
}
