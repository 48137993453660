import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";
import { MdOutlineCloudUpload } from "react-icons/md"; // Cloud-Based Model Deployment
import { MdOutlineDevices } from "react-icons/md";
import { MdOutlineLock } from "react-icons/md";
import { MdOutlineVpnKey } from "react-icons/md";
import { PiComputerTower } from "react-icons/pi";
import img1 from "../../../../assets/img/Services/IAMSecuritySolutions/key2.jpg";

export default function MainSection4() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Zero Trust Privileged Security
            </h1>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineLock className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Least Privilege Access
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Enforce a least-privilege approach to ensure users only have
                access to the resources they need for their role.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineVpnKey className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  {" "}
                  Continuous Authentication
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Verify user identity throughout privileged sessions with
                real-time validation techniques.
              </p>
            </div>

            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <PiComputerTower className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  {" "}
                  Micro-Segmentation
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Isolate critical systems and resources to prevent lateral
                movement during a breach.
              </p>
            </div>
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}
