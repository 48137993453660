import React from "react";
import {
  MdOutlineSecurity,
  MdPrivacyTip,
  MdPhonelinkLock,
  MdAssessment,
  MdOutlinePolicy,
} from "react-icons/md";

import { LuBookMarked } from "react-icons/lu";
import { PiComputerTowerBold } from "react-icons/pi";
import img1 from "../../../../assets/img/Services/IAMSecuritySolutions/zero-trust-architecture.jpg"; // Ensure this path is correct

export default function MainSection2() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="relative row-span-2 md:row-span-1 h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Zero Trust Security Implementation"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Micro-Segmentation and Network Security{" "}
            </h1>
            {/* <p className="text-lg text-gray-600">
              AeroAegis provides Zero-Trust Security solutions that secure
              access to applications, data, and networks by enforcing continuous
              validation, least privilege access, and micro-segmentation.
            </p> */}
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 - Identity-Based Security */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <PiComputerTowerBold className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Isolated Workloads
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Divide your network into smaller zones to limit the impact of
                security breaches.
              </p>
            </div>

            {/* Feature 2 - Least Privilege Access */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdPrivacyTip className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Application-Specific Access
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Grant access only to specific applications rather than broad
                network segments.
              </p>
            </div>

            {/* Feature 3 - Continuous Risk Assessment */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <LuBookMarked className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Dynamic Access Policies{" "}
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Use AI to enforce context-aware access policies based on user
                behavior, location, device, and time of access.
              </p>
            </div>
          </div>
        </div>

        {/* Image Section */}
      </div>
    </div>
  );
}