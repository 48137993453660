import React from "react";
import {
  MdOutlineSecurity,
  MdPrivacyTip,
  MdPhonelinkLock,
  MdAssessment,
  MdOutlinePolicy,
} from "react-icons/md";
import img1 from "../../../../assets/img/Services/IAMSecuritySolutions/zero-trust.jpg"; // Ensure this path is correct

export default function MainSection() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="flex flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
        <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block mb-1 sm:mb-4">Our Offerings</span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
        </h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Identity-Centric Security
            </h1>
            {/* <p className="text-lg text-gray-600">
              AeroAegis provides Zero-Trust Security solutions that secure
              access to applications, data, and networks by enforcing continuous
              validation, least privilege access, and micro-segmentation.
            </p> */}
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 - Identity-Based Security */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineSecurity className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Multi-Factor Authentication (MFA)
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                &nbsp;Add an extra layer of security by combining passwords with
                biometrics, tokens, or mobile-based authentication.
              </p>
            </div>

            {/* Feature 2 - Least Privilege Access */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdPrivacyTip className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Single Sign-On (SSO)
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Streamline secure access to multiple applications while reducing
                password management risks.
              </p>
            </div>

            {/* Feature 3 - Continuous Risk Assessment */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdAssessment className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Identity Verification and Access Controls
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Continuously validate user identity using advanced AI and
                behavioral analytics.
              </p>
            </div>
          </div>
        </div>
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Zero Trust Security Implementation"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>

        {/* Image Section */}
      </div>
    </div>
  );
}