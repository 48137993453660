import React from 'react';
import hero_img from '../../../../assets/img/Services/IAMSecuritySolutions/idacmm.png';
import { FaShieldAlt, FaUserLock, FaKey, FaCogs, FaRobot } from 'react-icons/fa';

export default function HeroSection() {
  return (
    <div className="relative flex flex-col py-16 lg:pt-0 lg:flex-col lg:pb-0">
      <div className="inset-y-0 top-0 right-0 z-0  xl:max-w-[680px] lg:max-w-[555px]  px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0  lg:absolute xl:px-0">
        <svg
          className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
          viewBox="0 0 100 100"
          fill="currentColor"
          preserveAspectRatio="none slice"
        >
          <path d="M50 0H100L50 100H0L50 0Z" />
        </svg>
        <img
          className="object-cover w-full bg-fixed h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
          src={hero_img}
          alt="hero"
        />
      </div>
      <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
        <div className="lg:mb-16 pr-14 mt-10 md:mt-10 lg:mt-10 xl:mt-14 pt-2 lg:my-20 lg:max-w-2xl ">
          <div className="p-2 w-6 h-2 flex bg-[#232f7f] rounded-full">
          </div>
          <h2 className="mb-5 text-3xl font-bold tracking-wide text-gray-900 lg:text-5xl sm:leading-none">
            Identity and Access Management (IAM) Services
          </h2>
          <p className="pr-6 lg:pr-[9rem] xl:pr-6  mb-5 text-base text-gray-700 md:text-lg">
            In an increasingly interconnected digital world, securing access to your organization's systems, data, and applications is more critical than ever. At {"AeroAegis"}, we provide comprehensive Identity and Access Management (IAM) solutions to help businesses centralize identity control, enhance security, and simplify access for employees, customers, and partners.With our expertise in leading IAM technologies like ForgeRock, Okta, Ping Identity, and Microsoft Azure AD, combined with AI-powered enhancements, we ensure your IAM strategy is tailored to meet your business goals.
          </p>
        </div>
      </div>
    </div>
  );
}
