import React from 'react';
import cloudIAM from '../../../../assets/img/Services/IAMSecuritySolutions/cloudIAM.jpg'; // Replace with the actual image path

export default function HeroSection() {
  return ( 
    <div className="relative flex flex-col py-16 lg:pt-0 lg:flex-col lg:pb-0">
      <div className="inset-y-0 top-0 right-0 z-0 xl:max-w-[650px] lg:max-w-[520px] px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
        <svg
          className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
          viewBox="0 0 100 100"
          fill="currentColor"
          preserveAspectRatio="none slice"
        >
          <path d="M50 0H100L50 100H0L50 0Z" />
        </svg>
        <img
          className="object-cover w-full bg-fixed h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
          src={cloudIAM}
          alt="Cloud IAM Solutions"
        />
      </div>
      <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
        <div className="pt-2 lg:my-16 lg:max-w-xl lg:pr-5">
          <p className="p-2 inline-block px-5 text-xs font-semibold tracking-wider text-white uppercase rounded-full bg-[#232f7f]">
          </p>
          <h2 className="mb-5 text-3xl font-bold tracking-wide text-gray-900 lg:text-4xl sm:leading-none">
            Cloud IAM Solutions: Simplifying Identity Management in the Cloud
          </h2>
          <p className="pr-5 lg:pr-[8.5rem] xl:pr-5  mb-5 text-base text-gray-700 md:text-lg">
            As businesses embrace cloud-first and hybrid environments, securing user access across multiple platforms is crucial. 
            AeroAegis Cloud IAM Solutions enable seamless identity management, 
            ensuring secure access and compliance in a dynamic cloud ecosystem.Leveraging industry-leading tools such as ForgeRock, Okta, Ping Identity, and Microsoft Azure AD, we design and deploy scalable solutions tailored to your cloud strategy, enabling secure, efficient, and centralized identity management.
          </p>
        </div>
      </div>
    </div>
  );
}
