import React from 'react';
import {
  FaCloudUploadAlt,
  FaCloud,
  FaBolt,
  FaShieldAlt,
  FaChartBar,
  FaChartLine,
  FaLightbulb,
  FaUserCheck,
  FaUsers,
  FaLock,
  FaClock,
  FaRocket,
  FaRegCheckCircle,
} from 'react-icons/fa';
import {
  HiOutlineCog,
  HiOutlineDesktopComputer,
  HiOutlineClipboardList,
  HiOutlineUserGroup,
} from 'react-icons/hi';
import { AiOutlineFileSearch } from 'react-icons/ai';

const CaseStudyPage = () => {
  return (
    <div className="mx-auto px-4 md:px-24 py-8 w-full max-w-7xl">
      <div className="uppercase tracking-wide text-1xl font-bold text-indigo-500">
        Case Study
      </div>
      <h1 className="mt-2 text-4xl font-bold text-gray-900 dark:text-white">
        Enhancing Banking Resilience with Big Data, Chaos Engineering, and
        DevOps
      </h1>
      <h2 className="mt-2 text-2xl font-semibold text-gray-700 dark:text-gray-300">
        AeroAegis Enables a Leading Bank to Achieve Reliability, Resilience, and
        SLA Optimization with SmartChaosLab
      </h2>
      <div className="mt-4 flex flex-wrap gap-4">
        <span className="inline-flex items-center rounded-md bg-blue-50 dark:bg-blue-900/20 px-2 py-1 text-xs font-medium text-blue-700 dark:text-blue-300 ring-1 ring-inset ring-blue-700/10 dark:ring-blue-300/20">
          <FaChartBar className="mr-1 h-3 w-3" />
          Big Data & Advanced Analytics
        </span>
        <span className="inline-flex items-center rounded-md bg-green-50 dark:bg-green-900/20 px-2 py-1 text-xs font-medium text-green-700 dark:text-green-300 ring-1 ring-inset ring-green-600/20 dark:ring-green-300/20">
          <HiOutlineCog className="mr-1 h-3 w-3" />
          Chaos Engineering for Banking Operations
        </span>
        <span className="inline-flex items-center rounded-md bg-yellow-50 dark:bg-yellow-900/20 px-2 py-1 text-xs font-medium text-yellow-800 dark:text-yellow-300 ring-1 ring-inset ring-yellow-600/20 dark:ring-yellow-300/20">
          <FaRegCheckCircle className="mr-1 h-3 w-3" />
          DevOps-Driven Reliability
        </span>
        <span className="inline-flex items-center rounded-md bg-red-50 dark:bg-red-900/20 px-2 py-1 text-xs font-medium text-red-700 dark:text-red-300 ring-1 ring-inset ring-red-600/20 dark:ring-red-300/20">
          <FaChartBar className="mr-1 h-3 w-3" />
          SLA & Business Impact Measurement
        </span>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4 mt-6">Case Study Description</h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          AeroAegis partnered with a leading global bank to enhance system
          resilience, improve service-level agreements (SLAs), and optimize
          business continuity using a combination of Big Data, Chaos
          Engineering, and DevOps best practices. The SmartChaosLab product from
          AeroAegis played a pivotal role in enabling the bank to measure
          business impact, blast radius, and reliability scores—helping the
          organization strengthen its digital banking operations against
          failures and improve customer experience.
        </p>
        <p>
          By leveraging AI-driven analytics, chaos engineering principles, and
          automated reliability assessments, AeroAegis delivered:
        </p>
        <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div className="flex items-center space-x-3">
            <AiOutlineFileSearch className="text-2xl text-blue-500" />
            <p className="text-gray-700 dark:text-gray-300">
              Proactive failure detection and risk mitigation in banking
              infrastructure.
            </p>
          </div>
          <div className="flex items-center space-x-3">
            <FaChartLine className="text-2xl text-green-500" />
            <p className="text-gray-700 dark:text-gray-300">
              Automated SLA monitoring with real-time business impact assessment
            </p>
          </div>
          <div className="flex items-center space-x-3">
            <HiOutlineDesktopComputer className="text-2xl text-yellow-500" />
            <p className="text-gray-700 dark:text-gray-300">
              Enhanced system reliability and resilience scoring using
              SmartChaosLab
            </p>
          </div>
          <div className="flex items-center space-x-3">
            <FaClock className="text-2xl text-purple-500" />
            <p className="text-gray-700 dark:text-gray-300">
              DevOps-driven chaos testing to improve recovery strategies and
              performance.
            </p>
          </div>
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Client Background</h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          The banking and financial services sector faces increasing challenges
          in ensuring uptime, mitigating outages, and maintaining resilient
          digital services. With millions of transactions processed daily, any
          downtime or failure can result in revenue loss, regulatory penalties,
          and damaged customer trust.
        </p>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          Our client, a top-tier global bank, operates across multiple regions,
          providing services such as:
          <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 mb-4">
            <li>Retail and Corporate Banking</li>
            <li>Digital Payments and Transactions</li>
            <li>Wealth and Asset Management</li>
          </ul>
        </p>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          Despite robust infrastructure investments, the client faced
          difficulties in tracking SLA violations, identifying system
          weaknesses, and ensuring fault tolerance across its critical
          applications. The bank sought a proactive and AI-driven solution to
          enhance system reliability while enabling data-driven decisions for
          optimizing IT resilience.
        </p>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          Key challenges included:
        </p>
        <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 mb-4">
          <li>
            Limited real-time insights into SLA performance and business impact
          </li>
          <li>
            Inability to quantify reliability risks before failures occur.
          </li>
          <li>
            Difficulty implementing chaos engineering in a regulated banking
            environment.
          </li>
          <li>
            Inefficient incident response due to lack of automated resilience
            assessments.
          </li>
        </ul>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          To solve these challenges, AeroAegis deployed SmartChaosLab, an
          AI-driven reliability and resilience platform, enabling the bank to
          measure SLAs dynamically, run controlled failure simulations, and
          improve operational resilience.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
          Challenges Faced by the Client
        </h2>
        <ol className="list-decimal list-inside text-gray-700 dark:text-gray-300">
          <li className="mb-4">
            <strong>SLA Violations & Lack of Real-Time Insights</strong>
            <p className="text-gray-700 dark:text-gray-300 mt-2">
              SLA breaches due to ineffective monitoring across digital
              services. Delayed incident resolution caused by lack of real-time
              insights, impacting customer experience.
            </p>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
                Banking operations struggled to measure and enforce SLAs across
                multiple digital services.
              </li>
              <li>
                Existing monitoring tools failed to correlate technical failures
                with business impact.
              </li>
              <li>
                Reactive incident management led to prolonged recovery times and
                degraded customer experience.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Unreliable System Resilience & Testing</strong>
            <p className="text-gray-700 dark:text-gray-300 mt-2">
              Traditional load testing failed to predict real-world failures,
              leading to unexpected downtimes. The absence of chaos engineering
              and automated resilience frameworks increased operational risks.
            </p>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
                Traditional load testing and failover mechanisms could not
                predict real-world failure scenarios
              </li>
              <li>
                Lack of chaos engineering principles in banking operations
                increased risks of unexpected downtimes.
              </li>
              <li>
                No automated framework to measure reliability and resilience
                scores.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>
              DevOps and Continuous Integration (CI/CD) Limitations
            </strong>
            <p className="text-gray-700 dark:text-gray-300 mt-2">
              Manual incident response and rollback procedures led to slow
              recovery cycles. Fragmented DevOps workflows delayed fault
              injection testing and disaster recovery planning, increasing
              business risks.
            </p>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
                Slow recovery cycles due to manual incident response and
                rollback procedures.
              </li>
              <li>
                Fragmented DevOps workflows, causing delays in fault injection
                testing and disaster recovery planning.
              </li>
              <li>
                Poor visibility into blast radius of failures, leading to higher
                business impact than necessary.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Compliance and Risk Management Challenges</strong>
            <p className="text-gray-700 dark:text-gray-300 mt-2">
              Regulatory constraints hindered real-world failure simulations,
              impacting risk preparedness. Inconsistent risk assessments and
              limited analytics complicated audits and compliance reporting.
            </p>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
                Regulatory constraints made it difficult to implement real-world
                failure simulations.
              </li>
              <li>
                Inconsistent risk assessments made audits complex and slowed
                compliance reporting.
              </li>
              <li>
                Limited analytics for post-incident reviews and root cause
                analysis.
              </li>
            </ul>
          </li>
        </ol>
      </div>

      <div className="mb-8">
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          AeroAegis engineered a holistic solution that leveraged Big Data,
          AI-driven analytics, Chaos Engineering, and DevOps automation to
          enhance the bank’s resilience.
        </p>
        <h2 className="text-2xl font-bold mb-4">AeroAegis's Approach</h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          To address the challenges, AeroAegis deployed SmartChaosLab, an
          advanced AI-driven resilience and reliability testing platform, along
          with a comprehensive big data analytics framework to enhance the
          bank’s SLA performance, system reliability, and DevOps-driven
          resilience.
        </p>
        <ol className="list-decimal list-inside text-gray-700 dark:text-gray-300">
          <li className="mb-4">
            <strong>Big Data & AI-Driven Analytics for SLA Monitoring</strong>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
                SmartChaosLab integrated with the bank’s IT operations to
                analyze SLA adherence, business impact, and incident response
                efficiency in real time
              </li>
              <li>
                AI-driven predictive analytics provided insights into potential
                SLA violations before they occurred.
              </li>
              <li>
                Custom-built dashboards offered a real-time resilience
                score based on historical failure patterns and system
                performance data..
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Chaos Engineering for Reliability & Resilience</strong>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
                SmartChaosLab enabled safe, controlled chaos testing within the
                bank’s DevOps pipeline, ensuring that failures could be
                simulated without regulatory risks.
              </li>
              <li>
                Resilience testing frameworks were built to evaluate blast
                radius impact, recovery times, and fault injection scenarios.
              </li>
              <li>
                Automated failure simulations tested system behavior under
                conditions like:
                <ul className='list-disc list-inside text-gray-700 dark:text-gray-300 mb-4'>
                  <li>
                    Database failures in high-traffic banking transactions.
                  </li>
                  <li>
                    Network disruptions affecting real-time payment processing.
                  </li>
                  <li>Microservice failures within critical banking APIs.</li>
                </ul>
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>DevOps-Driven Automation & Recovery</strong>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
                Continuous testing within CI/CD pipelines allowed developers to
                identify systemic weaknesses before production deployment.
              </li>
              <li>
                Automated rollback mechanisms improved failure recovery by 60%,
                reducing downtime
              </li>
              <li>
                Real-time SLA enforcement policies were applied dynamically
                based on AI-driven failure prediction models.
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Regulatory Compliance and Risk Management</strong>
            <ul className="list-disc list-inside ml-6 mt-2">
              <li>
                AeroAegis ensured that all chaos experiments were aligned with
                banking regulatory frameworks to prevent unauthorized
                disruptions.
              </li>
              <li>
                Automated compliance reporting improved audit readiness by
                reducing manual effort by 70%
              </li>
              <li>
                Data-driven risk assessments optimized banking risk models,
                ensuring continuous improvement in system resilience.
              </li>
            </ul>
          </li>
        </ol>
      </div>

      <div class="mb-8">
        <h2 class="text-2xl font-bold mb-4">Key Outcomes and Benefits</h2>
        <ol class="list-decimal list-inside text-gray-700 dark:text-gray-300">
          <li class="mb-4">
            <strong>
              Enhanced SLA Performance and Business Impact Visibility
            </strong>
            <ul class="list-disc list-inside ml-6 mt-2">
              <li>
                <strong>Improved SLA Compliance:</strong> 40% reduction in SLA
                violations with real-time monitoring and AI-driven analytics.
              </li>
              <li>
                <strong>Enhanced Response Tracking:</strong> SmartChaosLab
                improved response time tracking, ensuring business impact was
                assessed in real-time.
              </li>
            </ul>
          </li>

          <li class="mb-4">
            <strong> Increased System Reliability and Resilience</strong>
            <ul class="list-disc list-inside ml-6 mt-2">
              <li>
                <strong>Improved Reliability Score:</strong> Reliability score
                improved by 35%, reducing risk exposure for critical banking
                operations.
              </li>
              <li>
                <strong>Proactive Vulnerability Detection:</strong> Automated
                chaos engineering experiments helped uncover system
                vulnerabilities before real-world failures occurred.
              </li>
              <li>
                <strong>Enhanced Failover Efficiency:</strong> Optimized
                failover processes reduced downtime and improved disaster
                recovery time by 50%.
              </li>
            </ul>
          </li>

          <li class="mb-4">
            <strong>DevOps Efficiency and Faster Recovery</strong>
            <ul class="list-disc list-inside ml-6 mt-2">
              <li>
                <strong>Faster Incident Resolution:</strong> CI/CD pipeline
                integration enabled automated testing, reducing incident
                resolution times by 60%.
              </li>
              <li>
                <strong>Quick Service Restoration:</strong> Automated rollback
                mechanisms ensured faster service restoration after failures
              </li>
              <li>
                <strong>Reduced Financial Losses:</strong> Improved incident
                response times, reducing financial losses during system
                disruptions.
              </li>
            </ul>
          </li>

          <li class="mb-4">
            <strong>Compliance Readiness and Risk Management</strong>
            <ul class="list-disc list-inside ml-6 mt-2">
              <li>
                <strong>Streamlined Compliance Reporting:</strong> Automated
                compliance reporting reduced regulatory audit preparation time
                by 70%.
              </li>
              <li>
                <strong>Enhanced Risk Assessment:</strong> Built-in resilience
                scoring improved the bank’s risk assessment models.
              </li>
              <li>
                <strong>Prioritized System Security:</strong> Business impact
                modeling helped prioritize mission-critical systems for security
                and reliability improvements.
              </li>
            </ul>
          </li>
        </ol>
      </div>

      <div className="bg-blue-50 dark:bg-blue-900 border-l-4 border-blue-500 p-4 mb-8">
        <p className="text-blue-700 dark:text-blue-300">
          This case study demonstrates how SmartChaosLab from AeroAegis
          transformed a leading bank’s operational resilience using a
          combination of Big Data analytics, Chaos Engineering, and DevOps
          automation. The banking sector requires continuous improvement in
          system reliability, and AI-driven solutions are key to building
          proactive security and resilience strategies.
        </p>
      </div>

      <div className="mb-8">
        <p className="text-xl font-bold mb-6">
          SmartChaosLab is redefining how financial institutions approach
          resilience and reliability, enabling banks to stay ahead of
          disruptions and maintain exceptional service continuity.
        </p>
        <h2 className="text-2xl font-bold mb-3">
          By partnering with AeroAegis, the client achieved:
        </h2>
        <ul class="list-disc list-inside ml-6 mt-2">
          <li>
            Proactive risk mitigation and resilience testing for critical
            banking operations
          </li>
          <li>Data-driven SLA management and business impact measurement.</li>
          <li>
            An automated framework for improving system reliability, blast
            radius tracking, and failure response.
          </li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Why Choose AeroAegis?</h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          AeroAegis is a leader in AI-driven resilience, big data analytics, and
          DevOps automation, delivering next-generation solutions that enhance
          banking IT infrastructure, security, and compliance.
        </p>
      </div>

      <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg mb-8">
        <h2 className="text-2xl font-bold mb-4">
          Contact AeroAegis today to revolutionize your banking resilience
          strategy and ensure continuous, failure-proof operations.
        </h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          Visit{' '}
          <a
            href="http://www.aeroaegis.com"
            className="text-blue-500 hover:underline"
          >
            www.aeroaegis.com
          </a>{' '}
          or reach out to our experts for a personalized consultation. Together,
          we can build innovative, resilient, and scalable solutions that drive
          operational excellence in the Banking.
        </p>
        <p className="text-gray-700 dark:text-gray-300 font-semibold">
          Let's shape the future of banking operations—one solution at a time.
        </p>
      </div>
    </div>
  );
};

export default CaseStudyPage;
