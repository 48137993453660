import React from "react";
import img1 from "../../../assets/img/Industries/Aerospace/15b15e51-0665-4283-8de9-32c0d7913630.jpg";

export default function HeroSection() {
  return (
    <div className="relative flex flex-col py-16 lg:pt-0 lg:flex-col lg:pb-0">
      <div className="inset-y-0 top-0 right-0 z-0 xl:max-w-[650px] lg:max-w-[520px] max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:absolute xl:px-0">
        <svg
          className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
          viewBox="0 0 100 100"
          fill="currentColor"
          preserveAspectRatio="none slice"
        >
          <path d="M50 0H100L50 100H0L50 0Z" />
        </svg>
        <img
          className="object-cover w-full bg-fixed h-56 rounded  shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
          src={img1}
          alt="hero"
        />
      </div>
      <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
        <div className="lg:mb-16 pt-2 lg:my-20 lg:max-w-xl lg:pr-5">
          <p className="p-2 inline-block px-5 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-[#232f7f]"></p>
          <h2 className="mb-1 text-3xl font-bold tracking-wide text-gray-900 lg:text-5xl sm:leading-none">
            Aerospace
          </h2>
          <p className="mb-5 text-blue-800 font-semibold">
            Innovating Aerospace with Next-Generation Technology
          </p>
          <p className="pr-5 lg:pr-[8.5rem] xl:pr-5  mb-5 text-base text-gray-700 md:text-lg">
            The aerospace industry is navigating an era of rapid innovation,
            driven by the need for greater efficiency, enhanced safety, and
            advanced engineering capabilities. At Aero Aegis, we empower
            aerospace companies to harness cutting-edge technologies like AI/ML,
            IoT, blockchain, and digital twin solutions to streamline
            operations, enhance manufacturing, and ensure regulatory compliance
            while driving innovation across the value chain.
          </p>
        </div>
      </div>
    </div>
  );
}
