import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";
import { IoLockClosedOutline } from "react-icons/io5";
import { BiLike } from "react-icons/bi";
import { MdOutlineInsights } from "react-icons/md"; // Tailored Predictive Analytics
import { MdOutlineLightbulb } from "react-icons/md";
import img1 from "../../../../assets/img/Services/IAMSecuritySolutions/security.jpg";
import { MdOutlineEnhancedEncryption } from "react-icons/md";
import { MdOutlinePassword } from "react-icons/md";
import { RiRecordCircleLine } from "react-icons/ri";

export default function MainSection1() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="flex flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
        <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block mb-1 sm:mb-4">
            Our Offerings
          </span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
        </h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              Privileged Account Security
            </h1>
            {/* <p className="text-lg text-gray-600">
              Develop bespoke models for predictive analytics, recommendation
              engines, fraud detection, and more.
            </p> */}
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlineEnhancedEncryption className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Credential Vaulting
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Securely store privileged account credentials in an encrypted
                vault to prevent unauthorized access.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MdOutlinePassword className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Password Rotation and Management
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Automate password rotation and enforce strong password policies
                to reduce the risk of credential theft.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <RiRecordCircleLine className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">
                  Session Recording and Monitoring
                </h3>
              </div>
              <p className="text-gray-600 text-sm">
                Capture all privileged account activities with detailed logs and
                video recordings for transparency and audit readiness.
              </p>
            </div>
          </div>
        </div>

        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="Workspace with laptop and plant"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
}
