import React from "react";
import { FaBrain, FaCog, FaIndustry, FaRocket } from "react-icons/fa";
import {
  BsGraphUp,
  BsPerson,
  BsArrowRepeat,
  BsCompass,
  BsDatabase,
  BsArrowRight,
} from "react-icons/bs";
import { GiSparkles } from "react-icons/gi";

const TechPartnerCard = ({ icon, title, description }) => (
  <div className="group relative p-8 bg-white rounded-2xl hover:shadow-2xl transition-all duration-500 border border-gray-100 overflow-hidden shadow-lg">
    <div className="absolute inset-0 bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
    <div className="absolute top-0 right-0 w-32 h-32 bg-blue-100 rounded-full blur-3xl opacity-20 -mr-16 -mt-16" />
    <div className="relative">
      <div className="flex items-start space-x-4 mb-6">
        <div className="p-3 bg-gradient-to-br from-blue-500 to-indigo-600 rounded-xl text-white shadow-lg">
          {icon}
        </div>
        <h3 className="text-xl font-bold text-gray-800 group-hover:text-blue-700 transition-colors duration-300">
          {title}
        </h3>
      </div>
      <p className="text-gray-600 leading-relaxed">{description}</p>
      <div className="absolute top-2 right-2">
        <GiSparkles className="w-6 h-6 text-blue-300 opacity-60" />
      </div>
    </div>
  </div>
);

const ServiceCard = ({ icon, title, description, br = false }) => (
  <div className="group md:with-[600px] md:h-[400px]">
    <div className="relative p-8 bg-white rounded-2xl shadow-lg hover:shadow-2xl transition-all duration-500 overflow-hidden">
      <div className="absolute top-0 right-0 -mt-8 -mr-8 w-32 h-32 bg-gradient-to-br from-blue-100 to-indigo-100 rounded-full opacity-70" />
      <div className="absolute bottom-0 left-0 mb-4 ml-4">
        <div className="w-24 h-24 bg-blue-50 rounded-full blur-xl opacity-50" />
      </div>
      <div className="relative">
        <span className="inline-flex items-center justify-center w-16 h-16 bg-gradient-to-br from-blue-500 to-indigo-600 text-white rounded-2xl shadow-lg mb-6 transform group-hover:-rotate-6 transition-transform duration-300">
          {icon}
        </span>
        <h3 className="text-2xl font-bold mb-4 text-gray-800 group-hover:text-blue-700 transition-colors duration-300">
          {title}
        </h3>
        <p className="text-gray-600 leading-relaxed mb-8">{description}</p>
        {br && <br />}
        <div className="flex items-center text-blue-600 font-medium group-hover:text-blue-700 transition-colors duration-300"></div>
      </div>
    </div>
  </div>
);

const TechnologyPartnersSection = () => {
  const partners = [
    {
      icon: <FaBrain className="w-6 h-6" />,
      title: "ForgeRock",
      description:
        "A comprehensive IAM platform offering adaptive authentication, self-service options, and enterprise-grade scalability.",
    },
    {
      icon: <FaCog className="w-6 h-6" />,
      title: "Okta",
      description:
        "Cloud-first IAM services with advanced capabilities for SSO, MFA, and lifecycle management.",
    },
    {
      icon: <FaIndustry className="w-6 h-6" />,
      title: "Ping Identity",
      description:
        "Secure, scalable identity federation and API security for hybrid cloud architectures.",
    },
    {
      icon: <FaRocket className="w-6 h-6" />,
      title: "Microsoft Azure AD",
      description:
        "Enterprise-grade IAM with deep integration into the Microsoft ecosystem.",
    },
  ];

  const services = [
    {
      icon: <BsGraphUp className="w-8 h-8" />,
      title: "IAM Strategy and Assessment",
      description:
        "Our experts conduct a comprehensive analysis of your existing identity systems to identify gaps and create a tailored IAM roadmap for your organization.",
    },
    {
      icon: <BsPerson className="w-8 h-8" />,
      title: "IAM System Design and Deployment",
      description:
        "Design a scalable IAM architecture that integrates with your current IT ecosystem. Implement leading IAM technologies to meet your unique needs.",
    },
    {
      icon: <BsArrowRepeat className="w-8 h-8" />,
      title: "Migration and Integration Services",
      description:
        "Seamlessly migrate legacy identity systems to modern IAM platforms with minimal disruption. Integrate IAM solutions with your existing applications.",
    },
    {
      icon: <BsCompass className="w-8 h-8" />,
      title: "Compliance and Governance Automation",
      description:
        "Implement real-time monitoring and reporting tools to simplify compliance with regulations like GDPR, HIPAA, and PCI DSS.",
      br: true,
    },
    {
      icon: <BsDatabase className="w-8 h-8" />,
      title: "Managed IAM Services",
      description:
        "Offload IAM management with our ongoing monitoring, maintenance, and optimization services. Receive proactive support to address emerging threats.",
    },
  ];

  return (
    <>
      <section className="py-24 bg-white relative overflow-hidden">
        <div className="absolute inset-0 bg-grid-gray-100 opacity-20" />
        <div className="absolute inset-0 bg-white" />
        <div className="container mx-auto px-4 relative">
          <div className="max-w-3xl mx-auto text-center mb-20">
            <h2 className="text-5xl font-bold text-gray-900 mb-6 leading-tight">
              Our Technology Partners
            </h2>
            <p className="text-xl text-gray-600 leading-relaxed">
            With our expertise in leading IAM technologies like ForgeRock, Okta, Ping Identity, and Microsoft Azure AD, combined with AI-powered enhancements, we ensure your IAM strategy is tailored to meet your business goals.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {partners.map((partner, index) => (
              <TechPartnerCard key={index} {...partner} />
            ))}
          </div>
        </div>
      </section>

      {/* <section className="py-24 pt-5 bg-white relative">
        <div className="absolute inset-0 bg-grid-gray-100 opacity-20" />
        <div className="container mx-auto px-4 relative">
          <div className="max-w-3xl mx-auto text-center mb-20">
            <h2 className="text-5xl font-bold text-gray-900 mb-6 leading-tight">
              Our IAM Implementation Services
            </h2>
            <p className="text-xl text-gray-600 leading-relaxed">
              End-to-end identity and access management solutions tailored to
              your needs
            </p>
          </div>
          <div className="flex flex-wrap justify-center gap-8">
            {services.map((item, index) => (
              <div
                key={index}
                className="w-[400px] card border border-primary shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2"
              >
                <div className="card-body items-center">
                  <div className="text-4xl text-primary mb-4">{item.icon}</div>
                  <h3 className="card-title text-start text-xl mb-2 w-full">{item.title}</h3>
                  <p className="text-start">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section> */}
    </>
  );
};

export default TechnologyPartnersSection;