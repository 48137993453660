import React from "react";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCoinStack } from "react-icons/bi";
import { IoLockClosedOutline } from "react-icons/io5";
import { BiLike } from "react-icons/bi";
import img1 from "../../../../assets/img/Products/AeroPerformai/AI.png";
import { FaExclamationTriangle, FaChartLine, FaCogs } from "react-icons/fa";

export default function PaymentServices() {
  return (
    <div className="container mx-auto px-4 py-12 md:py-12 md:px-10">
      <div className="md:grid flex flex-col-reverse md:grid-cols-2 gap-8 items-center">
        <div className="relative h-[500px] rounded-3xl overflow-hidden">
          <img
            src={img1}
            alt="AI-Driven Compliance Enhancements"
            className="object-cover w-full h-full rounded-3xl"
          />
        </div>
        <div className="space-y-8">
          <div className="space-y-4">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
              AI-Driven Compliance Enhancements
            </h1>
            <p className="text-lg text-gray-600">
              Leverage AI and machine learning to optimize compliance processes and detect potential risks.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Feature 1 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaExclamationTriangle className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Anomaly Detection</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Identify suspicious patterns in user behavior or system activity that could indicate non-compliance.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaChartLine className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Predictive Analytics</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Forecast compliance risks and recommend proactive measures to mitigate them.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <FaCogs className="w-6 h-6 text-indigo-600" />
                <h3 className="font-semibold text-gray-900">Intelligent Policy Updates</h3>
              </div>
              <p className="text-gray-600 text-sm">
                Use AI to analyze regulatory changes and suggest policy adjustments in real time.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
