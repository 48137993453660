import React from 'react'
import Herosection from './Herosection'
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'
import Section4 from './Section4'
import Section5 from './Section5';
import Section6 from './Section6';

export default function index() {
  return (
    <div>
        <Herosection/>
        <AnimationRevealPage>
        <div className="flex flex-col mb-6 justify-center lg:justify-center lg:flex-row md:mb-8">
        <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block mb-1 sm:mb-4">Our Case Studies</span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-indigo-600 scale-x-30 group-hover:scale-x-100" />
        </h2>
      </div>
          <div className='grid grid-cols-1 md:grid-cols-2  gap-2 py-5 px-5'>
         
          <Section1/>
          <Section2/>
            <Section3/>
            <Section4/>
            <Section5/>
            <Section6 />
           
           
          </div>
        </AnimationRevealPage>
    </div>
  )
}
