import React from "react";
import { FaBrain, FaCog, FaIndustry, FaRocket } from "react-icons/fa";
import {
  BsGraphUp,
  BsPerson,
  BsArrowRepeat,
  BsCompass,
  BsDatabase,
  BsArrowRight,
} from "react-icons/bs";
import { GiSparkles } from "react-icons/gi";
import IndustriesWeServe from "./IndustriesWeServe";

const TechPartnerCard = ({ icon, title, description }) => (
  <div className="group relative p-8 bg-white rounded-2xl hover:shadow-2xl transition-all duration-500 border border-gray-100 overflow-hidden shadow-lg">
    <div className="absolute inset-0 bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
    <div className="absolute top-0 right-0 w-32 h-32 bg-blue-100 rounded-full blur-3xl opacity-20 -mr-16 -mt-16" />
    <div className="relative h-full flex-col flex justify-start">
      <div className="flex items-start space-x-4 min-h-[80px]">
        <div className="self-start p-3 bg-gradient-to-br from-blue-500 to-indigo-600 rounded-xl text-white shadow-lg">
          {icon}
        </div>
        <h3 className="text-xl font-bold text-gray-800 group-hover:text-blue-700 transition-colors duration-300">
          {title}
        </h3>
      </div>
      <p className="text-gray-600 leading-relaxed mt-0">{description}</p>
      <div className="absolute top-2 right-2">
        <GiSparkles className="w-6 h-6 text-blue-300 opacity-60" />
      </div>
    </div>
  </div>
);

const ServiceCard = ({ icon, title, description }) => (
  <div className="group h-full flex flex-col">
    <div className="relative p-4 bg-white rounded-2xl shadow-lg hover:shadow-2xl transition-all duration-500 overflow-hidden flex flex-col h-full">
      <div className="absolute top-0 right-0 -mt-8 -mr-8 w-32 h-32 bg-gradient-to-br from-blue-100 to-indigo-100 rounded-full opacity-70" />
      <div className="absolute bottom-0 left-0 mb-4 ml-4">
        <div className="w-24 h-24 bg-blue-50 rounded-full blur-xl opacity-50" />
      </div>
      <div className="relative flex flex-col flex-grow p-6">
        <span className="inline-flex items-center justify-center w-16 h-16 bg-gradient-to-br from-blue-500 to-indigo-600 text-white rounded-2xl shadow-lg mb-6 transform group-hover:-rotate-6 transition-transform duration-300">
          {icon}
        </span>
        <h3 className="text-2xl font-bold mb-4 text-gray-800 group-hover:text-blue-700 transition-colors duration-300">
          {title}
        </h3>
        <p className="text-gray-600 leading-relaxed flex-grow">{description}</p>
        <div className="flex items-center text-blue-600 font-medium group-hover:text-blue-700 transition-colors duration-300 mt-4"></div>
      </div>
    </div>
  </div>
);

const TechnologyPartnersSection = () => {
  const partners = [
    {
      icon: <FaBrain className="w-6 h-6" />,
      title: "Splunk",
      description:
        "Monitor, analyze, and report compliance data in real time with powerful analytics capabilities.",
    },
    {
      icon: <FaCog className="w-6 h-6" />,
      title: "Microsoft Compliance Manager",
      description:
        "Automate and manage compliance activities across Microsoft ecosystems.",
    },
    {
      icon: <FaIndustry className="w-6 h-6" />,
      title: "Blockchain Solutions",
      description:
        "Use blockchain for tamper-proof audit trails and transparent reporting.",
    },
    {
      icon: <FaRocket className="w-6 h-6" />,
      title: "ServiceNow GRC",
      description:
        "Centralize compliance processes and enable real-time monitoring with governance, risk, and compliance (GRC) tools.",
    },
  ];

  const services = [
    {
      icon: <BsGraphUp className="w-8 h-8" />,
      title: "End-to-End Expertise",
      description:
        "From gap analysis to audit preparation, AeroAegis delivers comprehensive compliance solutions.",
    },
    {
      icon: <BsArrowRepeat className="w-8 h-8" />,
      title: "Proactive Approach",
      description:
        "Stay ahead of regulatory changes with real-time updates and predictive insights.",
    },
    {
      icon: <BsCompass className="w-8 h-8" />,
      title: "Advanced Technologies",
      description:
        "Leverage AI, blockchain, and cloud-native tools to enhance compliance processes and reduce risks.",
    },
    {
      icon: <BsDatabase className="w-8 h-8" />,
      title: "Industry-Specific Focus",
      description:
        "Tailor compliance frameworks to meet the unique needs of your industry.",
    },
  ];

  return (
    <>
      <section className="py-24 bg-white relative overflow-hidden">
        <div className="absolute inset-0 bg-grid-gray-100 opacity-20" />
        <div className="absolute inset-0 bg-white" />
        <div className="container mx-auto px-4 relative">
          <div className="max-w-3xl mx-auto text-center mb-20">
            <h2 className="text-5xl font-bold text-gray-900 mb-6 leading-tight">
              Our Technology Partners
            </h2>
            <p className="text-xl text-gray-600 leading-relaxed">
              Leveraging industry-leading platforms to deliver robust identity
              solutions
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {partners.map((partner, index) => (
              <TechPartnerCard key={index} {...partner} />
            ))}
          </div>
        </div>
      </section>
      <IndustriesWeServe />

      {/* <section className="py-24 pt-5 bg-white relative">
        <div className="absolute inset-0 bg-grid-gray-100 opacity-20" />
        <div className="container mx-auto px-4 relative">
          <div className="max-w-3xl mx-auto text-center mb-20">
            <h2 className="text-5xl font-bold text-gray-900 mb-6 leading-tight">
              Why Choose AeroAegis for Compliance and Audit Readiness?
            </h2>
            <p className="text-xl text-gray-600 leading-relaxed">
              End-to-end identity and access management solutions tailored to your needs
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-8">
            {services.map((service, index) => (
              <ServiceCard key={index} {...service} />
            ))}
          </div>
        </div>
      </section> */}
    </>
  );
};

export default TechnologyPartnersSection;